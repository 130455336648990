import {Directive, ElementRef, HostListener} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[appPhoneNumberFormat]'
})
export class PhoneNumberFormatDirective {

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Remove all non-digits characters
    let cleaned = value.replace(/\D+/g, '');

    // Slice and insert dashes as necessary (xxx-xxx-xxxx)
    const matches = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const match = matches?.slice(1).filter(x => x).join('-');
    this.control.control?.setValue(match, { emitEvent: false });
    this.el.nativeElement.value = match;
  }

  @HostListener('blur')
  onBlur() {
    // When focus is lost, update the form's value without the dashes
    let value = this.el.nativeElement.value;
    this.control.control?.setValue(value.replace(/-/g, ''));
  }

}
