import {Component, Input, OnInit} from '@angular/core';
import {LoadingService} from "../../services/loading.service";
import {WsService} from "../../services/ws.service";
import {AlertService} from "../../services/alert.service";
import {CustomerPlaidInfo} from "../../models/CustomerPlaidInfo";
import {QrCodeService} from "../../services/qr-code.service";
import {CustomerAccount} from "../../models/CustomerAccount";
import {from} from "rxjs";

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  imageSrc: string | ArrayBuffer | null = '';
  @Input() customerAccount!: CustomerAccount
  maxFileSize = 5 * 1024 * 1024; // 5 MB
  @Input() tasks: { name: string; completed: boolean }[] = [];

  constructor(private customerService: WsService, private loadingService: LoadingService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    if(localStorage.getItem('sb|sidebar-toggle') && localStorage.getItem('sb|sidebar-toggle')==='true'){
      document.body.classList.toggle('sb-sidenav-toggled');
    }
  }

  toggleCompletion(task: any) {
    task.completed = !task.completed;
  }

  onFileChange($event: any) {
    this.loadingService.show();
    const file = $event.target.files[0];

    if (file && file.size > this.maxFileSize) {
      this.alertService.addAlert('error', 'File size should not exceed 5 MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = e => this.imageSrc = reader.result;

    if(file){
      reader.readAsDataURL(file);

      this.customerService.uploadDocument(file, this.customerAccount.customerAccountUuid)
        .subscribe(response =>{
          this.loadingService.hide();
          this.alertService.addAlert('success','Successfully Uploaded');
        });
    }else {
      this.loadingService.hide();
      this.alertService.addAlert('error','Could not upload')
    }
  }
}
