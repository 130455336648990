import { Component } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {WsService} from "../../services/ws.service";
import {CustomerAccount} from "../../models/CustomerAccount";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {LoadingService} from "../../services/loading.service";
import { passwordValidator } from "../../functions/passwordValidator"
import {Router} from "@angular/router";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent {
  private customerAccount?: CustomerAccount
  isClean: boolean = true;

  signUpForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, passwordValidator()]],
    repeatPassword: ['', [Validators.required, passwordValidator()]],
    lastName: ['', Validators.required],
    firstName: ['', Validators.required],
    termsAgreement:[false, Validators.required]
  });

  constructor(private formBuilder: FormBuilder, private authService:AuthService,
              private customerService: WsService, private recaptchaV3Service: ReCaptchaV3Service,
              private loadingService: LoadingService, private router: Router, private alertService: AlertService) {
  }

  onSubmit(): void {
    if(this.signUpForm.value.termsAgreement){
      this.loadingService.show();
      this.recaptchaV3Service.execute('signup').subscribe({next: token=>{
          // send token to backend
          this.customerService.verifyCaptcha(token).subscribe({next: result => {
              if(result){
                this.authService.checkAccountProviders(this.signUpForm.value.email!).then(providers=>{
                  if (providers.length === 0) {
                    this.authService.signUp(this.signUpForm.value.email!, this.signUpForm.value.password!).then((userCredential)=>{
                      this.authService.setUserData(userCredential.user);
                      if(this.authService.getUser().uid) {
                        let newCustomer = {
                          "email": this.signUpForm.value.email!,
                          "customerContactInfoView": {
                            "firstName": this.signUpForm.value.firstName,
                            "lastName": this.signUpForm.value.lastName
                          },
                          "customerAccountUuid": this.authService.getUser().uid
                        }
                        this.customerService.addNewCustomer(newCustomer).subscribe({
                          next: (customerAccount)=> {
                            this.customerAccount = customerAccount
                            this.sendVerificationMail();
                          },
                          error: (e) =>{
                            this.loadingService.hide();
                            this.alertService.addAlert('error','Could not get the account created!');
                          }
                        });
                      }else {
                        this.loadingService.hide();
                      }
                    }).catch((error)=>{
                      this.loadingService.hide();
                      if(error.message.includes('email-already-in-use')){
                        this.alertService.addAlert('info','Your email is already in use!');
                      }else {
                        this.alertService.addAlert('error','An error occurred. Please try again later');
                      }
                    });
                  }else {
                    this.alertService.addAlert('info','Your email is already in use!');
                  }
                }).catch((error)=>{
                  this.loadingService.hide();
                  this.alertService.addAlert('error','Error while signing up')
                })
              }else {
                this.loadingService.hide();
              }
            },
            error: err => {
              this.loadingService.hide();
              this.alertService.addAlert('error','Error while signing up');
            }
          })
        },
        error: err => {
          this.loadingService.hide();
          this.alertService.addAlert('error','Error while signing up');
        }
      });
    }else {
      this.isClean = false;
    }
  }

  sendVerificationMail(){
    this.alertService.addAlert('info','Please wait while we set up your account!')
    this.customerService.sendEmailVerificationLink(this.signUpForm.value.email!).subscribe({
      next: a=>{
        this.loadingService.hide();
        if(a && a==='SUCCESS'){
          this.router.navigate(['verify-email-address']);
        }
      },
      error: err => {
        this.loadingService.hide();
        this.alertService.addAlert('error', 'Cannot send email verification link')
      }
    })
  }
}
