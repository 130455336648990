<div class="wrap-without-sidebar bg-light min-vh-100 top-0">
  <main>

    <div id="page" class="page font--jakarta">

      <!-- LOGIN PAGE
      ============================================= -->
      <div id="login" class="bg--scroll login-section division">
        <div class="container">
          <div class="row justify-content-center">

            <!-- REGISTER PAGE WRAPPER -->
            <div class="col-lg-11">
              <div class="register-page-wrapper r-16 bg--fixed">
                <div class="row">

                  <!-- LOGIN PAGE TEXT -->
                  <div class="col-md-6">
                    <div class="register-page-txt color--white">

                      <!-- Logo -->
                      <img class="img-fluid" src="assets/img/veriez-logo.png" alt="logo-image">

                      <!-- Title -->
                      <h2 class="s-42 w-700" style="color: #000000 !important;">Welcome</h2>
                      <h2 class="s-42 w-700" style="color: #000000 !important;">back to VeriEZ</h2>

                      <!-- Text -->
                      <!--<p class="p-md mt-25" style="color: #0074de !important;"><b>Verify in minutes</b>
                      </p>-->

                    </div>
                  </div>	<!-- END LOGIN PAGE TEXT -->

                  <!-- LOGIN FORM -->
                  <div class="col-md-6">
                    <div class="register-page-form">
                      <form [formGroup]="signInForm" (ngSubmit)="onSubmit()" *ngIf="currentStep === 1" class="row sign-in-form">
                        <!-- Google Button -->
                        <div class="col-md-12">
                          <a  href="#" class="btn btn-google ico-left">
                            <img src="assets/vendor/images/png_icons/google.png" alt="google-icon"> Sign in with Google
                          </a>
                        </div>

                        <!-- Login Separator -->
                        <div class="col-md-12 text-center">
                          <div class="separator-line">Or, sign in with your email</div>
                        </div>

                        <!-- Form Input -->
                        <div class="form-column mb-12">
                          <p class="p-sm input-header">Email address</p>
                          <input type="email" class="form-control email" id="email" name="email" formControlName="email" aria-describedby="emailHelp" placeholder="example@example.com">
                          <div *ngIf="signInForm.get('email')?.errors && signInForm.get('email')?.touched">
                            <small class="text-danger" *ngIf="signInForm.get('email')?.errors?.['required']">
                              Email is required.
                            </small>
                            <small class="text-danger" *ngIf="signInForm.get('email')?.errors?.['email']">
                              Enter a valid email.
                            </small>
                          </div>
                          <!-- Email verification error -->
                          <small class="text-danger" *ngIf="emailNotVerified">
                            Your email has not been verified. Please check your inbox.
                          </small>
                        </div>
                        <!-- Form Input -->
                        <div class="form-column mb-12">
                          <p class="p-sm input-header">Password</p>
                          <div class="wrap-input">
                            <span class="btn-show-pass ico-20"><span (click)="showHidePassword()" [ngClass]="!showPass? 'flaticon-visibility eye-pass' : 'flaticon-invisible eye-pass'"></span></span>
                            <input [type]="showPass ? 'text' : 'password'" class="form-control" id="password" name="password" formControlName="password" placeholder="* * * * * * * * *">
                            <div *ngIf="signInForm.get('password')?.errors && signInForm.get('password')?.touched">
                              <small class="text-danger" *ngIf="signInForm.get('password')?.errors?.['required']">
                                Password is required.
                              </small>
                            </div>
                          </div>
                        </div>
                        <!-- Reset Password Link -->
                        <div class="col-md-12">
                          <div class="reset-password-link">
                            <p class="p-sm"><a routerLink="/forgot-password" class="color--theme">Forgot your password?</a></p>
                          </div>
                        </div>

                        <!-- Form Submit Button -->
                        <div class="col-md-12">
                          <button type="submit" class="btn btn--theme hover--theme submit" [disabled]="!signInForm.valid">Sign In</button>
                          <small class="text-danger" *ngIf="badCredentials">
                            Username and/or Password Incorrect!
                          </small>
                          <small class="text-danger" *ngIf="accountDisabled">
                            Account temporary disabled. Try again later.
                          </small>
                        </div>

                        <!-- Sign Up Link -->
                        <div class="col-md-12">
                          <p class="create-account text-center">
                            Don't have an account? <a routerLink="/sign-up" class="color--theme">Sign up</a>
                          </p>
                        </div>
                      </form>
                      <form [formGroup]="phoneNumberForm" (ngSubmit)="onSubmitEnrollMFA()" *ngIf="currentStep === 2 && !isMFAEnrolled">
                        <div class="form-column mb-3">
                          <p class="p-sm input-header">Phone Number (2FA)</p>
                          <div class="input-group">
                            <span class="input-group-text">+1</span>
                            <div class="form-floating">
                              <input type="tel" id="phoneNumber" appPhoneNumberFormat class="form-control" formControlName="phoneNumber" name="phoneNumber" required>
                              <label for="phoneNumber" class="float-end">Phone Number</label>
                            </div>
                          </div>
                        </div>
                        <button type="submit" class="btn btn--theme hover--theme submit" [disabled]="!phoneNumberForm.valid" *ngIf="!isCodeSent">Send Code</button>
                      </form>
                      <form [formGroup]="verificationCodeForm" (ngSubmit)="onSubmitCode()" *ngIf="currentStep === 2 && isCodeSent">
                        <div class="form-column mb-3">
                          <p class="p-sm input-header">Verification Code</p>
                          <input type="text" id="verificationCode" class="form-control" formControlName="verificationCode" name="verificationCode" placeholder="Verification Code">
                        </div>
                        <button type="submit" class="btn btn--theme hover--theme submit" [disabled]="!verificationCodeForm.valid" *ngIf="isCodeSent">Submit Code</button>
                      </form>
                    </div>
                  </div>	<!-- END LOGIN FORM -->
                </div>  <!-- End row -->
              </div>	<!-- End register-page-wrapper -->
            </div>	<!-- END REGISTER PAGE WRAPPER -->
          </div>	   <!-- End row -->
        </div>	   <!-- End container -->
      </div>	<!-- END LOGIN PAGE -->
    </div>	<!-- END PAGE CONTENT -->
  </main>
  <app-footer></app-footer>
</div>
