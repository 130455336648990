import { Injectable } from '@angular/core';
import {Alert} from "../models/Alert";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alertSubject = new Subject<Alert[]>();
  private alerts: Alert[] = [];
  private alertId = 0;

  getAlerts(): Observable<Alert[]> {
    return this.alertSubject.asObservable();
  }

  addAlert(type: 'success' | 'error' | 'info', message: string, timeout = 3000): void {
    const alert: Alert = { id: this.alertId++, type, message };
    this.alerts.push(alert);
    this.alertSubject.next(this.alerts);

    setTimeout(() => this.removeAlert(alert.id), timeout);
  }

  private removeAlert(id: number): void {
    this.alerts = this.alerts.filter(alert => alert.id !== id);
    this.alertSubject.next(this.alerts);
  }
}
