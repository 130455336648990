import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {WsService} from "../../services/ws.service";
import {LoadingService} from "../../services/loading.service";
import {CustomerAccount} from "../../models/CustomerAccount";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {QrCodeService} from "../../services/qr-code.service";
import {AlertService} from "../../services/alert.service";
import {CustomerVerificationDetail} from "../../models/CustomerVerificationDetail";
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{
  isSettingSelected:boolean = false;
  isProfileSelected:boolean = false;
  isShareSelected:boolean = false;
  isVerificationSelected:boolean = false;
  isAnalyticsSelected:boolean = false;
  customerAccount!: CustomerAccount;
  @ViewChild('modalContent') modalContent: any;
  @ViewChild('newUserModalContent') newUserModalContent : any
  qrCodeUrl: string | null = null;
  states: any = [];
  countryCodes: any = [];
  currentStep: number = 0;
  selectedFile: File | null = null;
  imageSrc: string | ArrayBuffer | null = '';
  maxFileSize = 5 * 1024 * 1024; // 5 MB
  verificationDetail: CustomerVerificationDetail = <CustomerVerificationDetail>{};
  showOtherInput = false;
  bannerMessage: string = '';
  bannerUrl: string = '';
  showBanner: boolean = false;
  tasks: { name: string; completed: boolean }[] = [];
  steps = [
    { title: 'Coming From?', active: true },
    { title: 'About You', active: false },
    { title: 'Contact Details', active: false },
    { title: 'Employment', active: false },
    { title: 'Account Activation', active: false },
    { title: 'Verification!', active: false },
  ];

  profileInfoForm = this.formBuilder.group({
    profilePicture: [null],
    dateOfBirth: [null, Validators.required],
    gender: [null],
    heightFeet:['', Validators.required],
    heightInches:['', Validators.required],
    weight: [null],
    race: [null],
    relationshipStatus: [null]
  });

  contactInfoForm = this.formBuilder.group({
    addressLine1: [null, Validators.required],
    addressLine2: [null],
    addressLine3: [null],
    countryCode: ['+1', Validators.required],
    city: [null, Validators.required],
    state: [null, Validators.required],
    zip: [null, [Validators.required, Validators.minLength(5)]]
  });

  employmentForm = this.formBuilder.group({
    employerName: [null, Validators.required],
    companyPhoneNumber: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]], // Example phone number validation pattern
    employerAddress1: [null, Validators.required],
    employerAddress2: [null],
    employerCity: [null, Validators.required],
    employerState: [null, Validators.required],
    employerZip: [null, [Validators.required, Validators.minLength(5)]],
    position: [null, Validators.required]
  });

  identificationForm = this.formBuilder.group({
    idNumber: [null, Validators.required]
  });

  marketingForm = this.formBuilder.group({
    referer: ['', Validators.required],
    otherInput: ['']
  });

  constructor(private formBuilder: FormBuilder, public authService:AuthService, private customerService: WsService,
              private loadingService: LoadingService, public modalService: NgbModal,
              public router: Router, private qrCodeService: QrCodeService, private alertService:AlertService) {
    this.loadingService.show();
  }

  ngOnInit(): void {
    this.customerService.getStates().subscribe(states=>{
      this.states = states;
    });
    this.customerService.getCountryCodes().subscribe(countryCodes =>{
      this.countryCodes = countryCodes;
    });
    this.marketingForm.valueChanges.subscribe(v =>{
      this.showOtherInput = v.referer === 'other';
    });
    if(!this.authService.getUserData() || !this.authService.getUserData().email || !this.authService.user.emailVerified){
      this.loadingService.hide();
      this.router.navigate(['sign-in']);
    }
    this.isProfileSelected = true;
    if(localStorage.getItem('sb|sidebar-toggle') && localStorage.getItem('sb|sidebar-toggle')==='true'){
      document.body.classList.toggle('sb-sidenav-toggled');
    }
    this.customerService.getAllCustomerAccountInfo(this.authService.getUserData().email).subscribe(
      {next: customerAccount => {
          if(customerAccount){
            this.loadingService.hide();
            this.customerAccount = customerAccount;
            let isNameVerified = false;
            if(!customerAccount.customerVerificationInfoViews || customerAccount.customerVerificationInfoViews.length < 2){
              this.customerService.updateEmailVerification(this.authService.getUserData().uid, {"emailVerified": "true", "emailVerifiedBy":"sign-up"}).subscribe();
            }
            if(customerAccount.customerVerificationInfoViews){
              customerAccount.customerVerificationInfoViews.forEach((view)=>{
                if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'PHOTO' && view.verified){
                  this.verificationDetail.photoVerified = true;
                  this.verificationDetail.photoVerifiedBy = view.verifiedBy;
                  this.verificationDetail.photoVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'BIRTHDATE' && view.verified){
                  this.verificationDetail.birthDateVerified = true;
                  this.verificationDetail.birthDateVerifiedBy = view.verifiedBy;
                  this.verificationDetail.birthDateVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'HEIGHT' && view.verified){
                  this.verificationDetail.heightVerified = true;
                  this.verificationDetail.heightVerifiedBy = view.verifiedBy;
                  this.verificationDetail.heightVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'WEIGHT' && view.verified){
                  this.verificationDetail.weightVerified = true;
                  this.verificationDetail.weightVerifiedBy = view.verifiedBy;
                  this.verificationDetail.weightVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'GENDER' && view.verified){
                  this.verificationDetail.genderVerified = true;
                  this.verificationDetail.genderVerifiedBy = view.verifiedBy;
                  this.verificationDetail.genderVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'RACE' && view.verified){
                  this.verificationDetail.raceVerified = true;
                  this.verificationDetail.raceVerifiedBy = view.verifiedBy;
                  this.verificationDetail.raceVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'RELATIONSHIP' && view.verified){
                  this.verificationDetail.relationshipStatusVerified = true;
                  this.verificationDetail.relationshipStatusVerifiedBy = view.verifiedBy;
                  this.verificationDetail.relationshipStatusVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'NAME' && view.verified){
                  isNameVerified = true;
                  this.verificationDetail.nameVerified = true;
                  this.verificationDetail.nameVerifiedBy = view.verifiedBy;
                  this.verificationDetail.nameVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'ADDRESS' && view.verified){
                  this.verificationDetail.addressVerified = true;
                  this.verificationDetail.addressVerifiedBy = view.verifiedBy;
                  this.verificationDetail.addressVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'PHONE' && view.verified){
                  this.verificationDetail.phoneNumberVerified = true;
                  this.verificationDetail.phoneNumberVerifiedBy = view.verifiedBy;
                  this.verificationDetail.phoneNumberVerifiedTimestamp = view.verificationTimestamp;
                }
                if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'EMAIL' && view.verified){
                  this.verificationDetail.emailVerified = true;
                  this.verificationDetail.emailVerifiedBy = view.verifiedBy;
                  this.verificationDetail.emailVerifiedTimestamp = view.verificationTimestamp;
                }
              })
            }
            this.tasks = [
              { name: 'Add Basic Information', completed: customerAccount && customerAccount.customerContactInfoView && customerAccount.customerContactInfoView.address1 !== undefined },
              { name: 'Subscribe', completed: customerAccount && customerAccount.customerSubscriptionView && customerAccount.customerSubscriptionView.subscribed },
              { name: 'Verify with Plaid', completed: customerAccount && isNameVerified },
              { name: 'Complete Checkr Background Check', completed: customerAccount && customerAccount.customerBackgroundInfoView && customerAccount.customerBackgroundInfoView.sexOffenderRegistry !== undefined },
              // Add more tasks as needed
            ];
            if(customerAccount && (!customerAccount.customerProfileInfoView || !customerAccount.customerProfileInfoView.dateOfBirth)){
              this.modalService.open(this.newUserModalContent, {ariaLabelledBy: 'staticBackdropLabel', centered: true, keyboard: false, backdrop: "static", windowClass: "customModalClass"})
            }
            if(!(customerAccount && customerAccount.customerSubscriptionView && customerAccount.customerSubscriptionView.subscribed)){
              this.showBanner = true;
              this.bannerMessage = 'Don\'t miss out on our premium features! Subscribe ';
              this.bannerUrl = customerAccount.customerSubscriptionView.payLinkUrl;
            }else{
              // subscribed!
              if(!isNameVerified && (!this.customerAccount.customerPlaidInfoView || !this.customerAccount.customerPlaidInfoView.validationUrl)){
                if(!customerAccount.customerContactInfoView.address1 || !customerAccount.customerContactInfoView.city || !customerAccount.customerContactInfoView.state ||
                  !customerAccount.customerContactInfoView.zipCode){
                  this.alertService.addAlert('error','Please update your contact information for verification');
                  this.showBanner = true;
                  this.bannerMessage = 'To start verifying, please update your contact information';
                }else {
                  this.showBanner = false;
                  this.alertService.addAlert('info','Subscription active! Verification will start soon...')
                  this.customerService.initialVerification(this.customerAccount.customerAccountUuid).subscribe(async url => {
                    if (url) {
                      this.alertService.addAlert('success', 'Initial Verification Successful!')
                      this.qrCodeUrl = await this.qrCodeService.generateQRCode(url);
                      this.bannerMessage = 'Start Plaid Identity Verification, click ';
                      this.bannerUrl = url;
                      this.showBanner = true;
                    } else {
                      this.alertService.addAlert('error', 'Could Not Perform Initial Verification')
                    }
                  });
                }
              } else if (!isNameVerified && this.customerAccount.customerPlaidInfoView && this.customerAccount.customerPlaidInfoView.validationUrl){
                this.bannerMessage = 'Continue With Identity Verification. Click ';
                this.bannerUrl = this.customerAccount.customerPlaidInfoView.validationUrl;
                this.showBanner = true;
              } else if (isNameVerified && !customerAccount.customerBackgroundInfoView && !customerAccount.customerSubscriptionView.backgroundCheckPaid){
                this.showBanner = true;
                this.bannerMessage = 'Order a Background Check to continue ';
                this.bannerUrl = '';
              } else if (customerAccount.customerSubscriptionView.backgroundCheckPaid
                && (!customerAccount.customerCheckrInfoView || !customerAccount.customerCheckrInfoView.checkrInvitationUrl)){
                this.alertService.addAlert('info','Generating Background Check Link');
                this.customerService.submitBackground(customerAccount.customerAccountUuid).subscribe(a=>{
                  if (a){
                    this.alertService.addAlert('success','Generated Checkr Invitation!');
                    this.showBanner = true;
                    this.bannerMessage = 'Start Your Background Check, Click ';
                    this.bannerUrl = a;
                  }else {
                    this.alertService.addAlert('error','Could not generate Checkr Invitation!');}
                });
              } else if (customerAccount.customerSubscriptionView.backgroundCheckPaid
                && customerAccount.customerCheckrInfoView.checkrInvitationUrl &&
                (!customerAccount.customerBackgroundInfoView || !customerAccount.customerBackgroundInfoView.sexOffenderRegistry)){
                this.showBanner = true;
                this.bannerMessage = 'Continue Your Background Check, Click ';
                this.bannerUrl = customerAccount.customerCheckrInfoView.checkrInvitationUrl;
              } else if (customerAccount.customerSubscriptionView.backgroundCheckPaid
                && customerAccount.customerCheckrInfoView.checkrInvitationUrl && customerAccount.customerBackgroundInfoView
                && customerAccount.customerBackgroundInfoView.sexOffenderRegistry && isNameVerified){
                this.showBanner = true;
                this.bannerMessage = 'Congratulations! You are fully verified! Share it with others!';
              }
            }
          }else {
            this.loadingService.hide();
            this.authService.signOut();
          }
        },error: err => {
          this.alertService.addAlert('error','Error loading profile');
          console.error(err.message);
          this.loadingService.hide();
        }
      });
  }

  selectedMenu(menuSelected: string) {
    this.toggleOff();
    if(menuSelected === 'profile'){
      this.isProfileSelected = true;
      document.getElementById('menu_profile')!.classList.toggle('active');
    }else if(menuSelected == 'settings') {
      this.isSettingSelected = true;
      document.getElementById('menu_settings')!.classList.toggle('active');
    }else if(menuSelected == 'share') {
      if(this.customerAccount.customerSubscriptionView && this.customerAccount.customerSubscriptionView.subscribed){
        this.isShareSelected = true;
      }else{
        this.alertService.addAlert('info', 'You need to activate your subscription to see this!');
        this.modalService.open(this.modalContent, {ariaLabelledBy: 'staticBackdropLabel', centered: true, keyboard: false, backdrop: "static"});
      }
      document.getElementById('menu_share')!.classList.toggle('active');
    }else if(menuSelected == 'verification') {
      if(this.customerAccount.customerSubscriptionView && this.customerAccount.customerSubscriptionView.subscribed) {
        this.isVerificationSelected = true;
      }else{
        this.alertService.addAlert('info', 'You need to activate your subscription to see this!');
        this.modalService.open(this.modalContent, {ariaLabelledBy: 'staticBackdropLabel', centered: true, keyboard: false, backdrop: "static"});
      }
      document.getElementById('menu_verification')!.classList.toggle('active');
    }else if(menuSelected == 'analytics') {
      if(this.customerAccount.customerSubscriptionView && this.customerAccount.customerSubscriptionView.subscribed){
        this.isAnalyticsSelected = true;
      }else{
        this.alertService.addAlert('info', 'You need to activate your subscription to see this!');
        this.modalService.open(this.modalContent, {ariaLabelledBy: 'staticBackdropLabel', centered: true, keyboard: false, backdrop: "static"});
      }
      document.getElementById('menu_analytics')!.classList.toggle('active');
    }
  }


  toggleOff(){
    const menuProfileEl = document.getElementById('menu_profile');
    if(menuProfileEl && menuProfileEl.classList.contains('active')){
      menuProfileEl.classList.toggle('active');
    }
    const menuShareEl = document.getElementById('menu_share');
    if(menuShareEl && menuShareEl.classList.contains('active')){
      menuShareEl.classList.toggle('active');
    }
    const menuAnalytics = document.getElementById('menu_analytics');
    if(menuAnalytics && menuAnalytics.classList.contains('active')){
      menuAnalytics.classList.toggle('active');
    }
    const menuVerification = document.getElementById('menu_verification');
    if(menuVerification && menuVerification.classList.contains('active')){
      menuVerification.classList.toggle('active');
    }
    const menuSettings = document.getElementById('menu_settings');
    if(menuSettings && menuSettings.classList.contains('active')){
      menuSettings.classList.toggle('active');
    }
    this.isProfileSelected = false;
    this.isSettingSelected = false;
    this.isShareSelected = false;
    this.isVerificationSelected = false;
    this.isAnalyticsSelected = false;
  }

  toggleLeftNav() {
    if(localStorage.getItem('mobile')==='true'){
      document.body.classList.toggle('sb-sidenav-toggled');
      localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled')?'true':'false');
    }
  }

  goToSubscription() {
    window.location.href = this.customerAccount.customerSubscriptionView.payLinkUrl;
  }

  onFileChange($event: any) {
    if ($event && $event.target && $event.target.files && $event.target.files[0]) {
      this.selectedFile = $event.target.files[0];

      if (this.selectedFile && this.selectedFile.size > this.maxFileSize) {
        this.alertService.addAlert('error', 'File size should not exceed 5 MB');
        this.selectedFile = null;
        this.profileInfoForm.value.profilePicture = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(this.selectedFile!);
    }
  }

  nextStep(): void {
    this.loadingService.show();
    if(this.currentStep === 0){
      this.customerService.addCustomerMarketing(this.customerAccount.customerAccountUuid, this.marketingForm.value).subscribe(marketing =>{
        this.loadingService.hide();
        this.currentStep++;
        this.setActiveStep(this.currentStep);
        this.marketingForm.reset();
      });
    }else if(this.currentStep === 1){
      /*if(this.profileInfoForm.value.profilePicture){
        this.customerService.uploadPhoto(this.selectedFile!, this.customerAccount.customerAccountUuid).subscribe();
      }*/
      this.customerService.addCustomerProfile(this.customerAccount.customerAccountUuid, this.profileInfoForm.value).subscribe(profile=>{
        this.customerAccount.customerProfileInfoView = profile;
        this.loadingService.hide();
        this.currentStep++;
        this.setActiveStep(this.currentStep);
        this.profileInfoForm.reset()
      });
    }else if(this.currentStep === 2){
      this.customerService.updateCustomerContact(this.customerAccount.customerAccountUuid, this.contactInfoForm.value).subscribe(contact=>{
        this.customerAccount.customerContactInfoView = contact;
        this.loadingService.hide();
        this.currentStep++;
        this.setActiveStep(this.currentStep);
        this.contactInfoForm.reset()
      });
    }else if(this.currentStep === 3){
      this.customerService.addCustomerEmployment(this.customerAccount.customerAccountUuid, this.employmentForm.value).subscribe(employment=>{
        this.customerAccount.customerEmploymentInfoView = employment;
        this.loadingService.hide();
        this.currentStep++;
        this.setActiveStep(this.currentStep);
        this.employmentForm.reset()
      });
    } else if(this.currentStep === 4){} else{
      this.modalService.dismissAll()
      this.loadingService.hide();
      this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
        this.router.navigate(['dashboard']);
      });
    }
  }

  prevStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.setActiveStep(this.currentStep);
    }
  }

  redirectToPlaid() {
    window.location.href=this.qrCodeUrl!;
  }

  skipEmployment() {
    if(this.currentStep == 3){
      this.currentStep = 4;
      this.setActiveStep(this.currentStep);
    }
  }

  setActiveStep(index: number) {
    this.steps.forEach((step, i) => {
      step.active = i === index;
    });
  }
}
