import { Component } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../../services/alert.service";
import {LoadingService} from "../../services/loading.service";
import {FormBuilder, Validators} from "@angular/forms";
import {passwordValidator} from "../../functions/passwordValidator";

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent {
  oobCode: string | undefined;
  mode: string | undefined;
  continueUrl: string | undefined;
  isResetPasswordMode: boolean = false;
  isResetPassword: boolean = false;
  isEmailVerifyMode: boolean = false;
  isEmailVerified: boolean = false;

  newPasswordForm = this.formBuilder.group({
    password: ['', [Validators.required, passwordValidator()]],
    repeatPassword: ['', [Validators.required, passwordValidator()]]
  });

  constructor(private auth: AuthService, private route: ActivatedRoute,
              private alertService: AlertService, private loadingService: LoadingService,
              private router: Router, private formBuilder: FormBuilder) {
    this.route.queryParams.subscribe(params =>{
      this.oobCode = params['oobCode'];
      this.mode = params['mode'];
      this.continueUrl = params['continueUrl'];
      switch (this.mode){
        case 'resetPassword':
          this.isResetPasswordMode=true;
          this.isEmailVerifyMode=false;
          break;
        case 'verifyEmail':
          this.isEmailVerifyMode=true;
          this.isResetPasswordMode=false;
          if(this.oobCode){
            this.handleVerifyEmail();
          }else {
            this.alertService.addAlert('error','URL is not well formatted')
          }
          break;
        default:
        // Error: invalid mode.
      }
    });
  }

  handleResetPassword(){
    this.loadingService.show();
    this.auth.confirmPasswordReset(this.newPasswordForm.value.password!,this.oobCode!).then((resp)=>{
      this.isResetPassword = true;
      this.loadingService.hide();this.alertService.addAlert('success','Password Reset! Redirecting...');
      setTimeout(()=>{
        this.router.navigate(['/sign-in'])
      }, 2500);
    }).catch((error) => {
      // Code is invalid or expired
      this.loadingService.hide();
      this.alertService.addAlert('error','Could not reset password!');
    });
  }

  handleVerifyEmail(){
    this.loadingService.show();
    this.auth.verifyEmail(this.oobCode!).then((resp)=>{
      // Email verified!
      this.isEmailVerified = true;
      this.loadingService.hide();
      this.alertService.addAlert('success','Email Verified! Redirecting...');
      setTimeout(()=>{
        this.router.navigate(['/sign-in'])
      }, 2500);
    }).catch((error) => {
      // Code is invalid or expired
      this.loadingService.hide();
      this.alertService.addAlert('error','Could not verify email!');
    });
  }

}
