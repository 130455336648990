import { Component } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {LoadingService} from "../../services/loading.service";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {WsService} from "../../services/ws.service";
import {Router} from "@angular/router";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent {
  private email: string = '';
  private password: string = '';

  signInForm = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, Validators.required]
  });
  emailNotVerified: boolean = false;
  badCredentials: boolean = false;
  accountDisabled: boolean = false;
  isMFAEnrolled: boolean = false;
  isCodeSent: boolean = false;
  currentStep: number = 1;
  uuid: string = '';
  showPass: boolean = false;

  phoneNumberForm = this.formBuilder.group({
    phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
  });

  verificationCodeForm = this.formBuilder.group({
    verificationCode: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder, public authService:AuthService,
              private loadingService: LoadingService, private recaptchaV3Service: ReCaptchaV3Service,
              private customerService: WsService, private router: Router,
              private alertService: AlertService) {
    this.authService.setUserData(undefined);
  }

  onSubmit(): void {
    this.badCredentials = false;
    this.accountDisabled = false;
    this.loadingService.show();
    this.email = this.signInForm.value.email!;
    this.password = this.signInForm.value.password!;
    this.recaptchaV3Service.execute("signin").subscribe({next: token => {
        this.customerService.verifyCaptcha(token).subscribe({next: result => {
            if(result){
              this.authService.signIn(this.email, this.password)
                .then(async (userCredential)=>{
                  // Enroll 2FA
                  let user = userCredential.user;
                  this.uuid = user.uid;
                  if(user.emailVerified){
                    this.authService.setUserData(user);
                    this.loadingService.hide();
                    this.currentStep = 2;
                  }else {
                    this.emailNotVerified = true;
                    this.loadingService.hide();
                  }
                }).catch((error) => {
                if(error.message.includes('invalid-login-credentials')){
                  this.badCredentials = true;
                  this.loadingService.hide();
                }else if(error.message.includes('Access to this account has been temporarily disabled due to many failed login attempts')){
                  this.accountDisabled = true;
                  this.loadingService.hide();
                }else if(error.code == 'auth/multi-factor-auth-required'){
                  this.isMFAEnrolled = true;
                  this.authService.resolveMFA(error).then(a =>{
                    if(a){
                      this.alertService.addAlert('success', 'SMS sent to your phone number');
                      this.isCodeSent = true;
                      this.currentStep = 2;
                    }else {
                      this.alertService.addAlert('error', 'Could not send SMS!');
                    }
                    this.loadingService.hide();
                  });
                }else {
                  this.loadingService.hide();
                  this.alertService.addAlert('error', 'Error while Signing you in');
                  console.error(error.message);
                }
              });
            }
          },
          error: err => {
            this.loadingService.hide();
            this.alertService.addAlert('error','Error while signing in')
          }
        });
      },
      error: err => {
        this.loadingService.hide();
        this.alertService.addAlert('error','Error while signing in')
      }
    });
    this.signInForm.reset();
  }

  onSubmitEnrollMFA(){
    this.loadingService.show();
    this.authService.sendMFACode('+1' + this.phoneNumberForm.value.phoneNumber!).then(a=>{
      if(a){
        this.alertService.addAlert('success', 'SMS Sent!');
        this.isCodeSent = true;
      }else {
        this.alertService.addAlert('error', 'Could not send SMS!');
      }
      this.loadingService.hide();
    }).catch((err)=>{
      this.alertService.addAlert('error','An error occurred! Refresh the page and try again');
      console.error(err.message);
      this.loadingService.hide();
    });
  }

  onSubmitCode(){
    this.loadingService.show();
    if(!this.isMFAEnrolled){
      this.authService.completeEnrollMFA(this.verificationCodeForm.value.verificationCode!).then(b=>{
        this.loadingService.hide();
        if(b){
          this.alertService.addAlert('success','Successfully added security!');
          this.loadingService.show();
          this.customerService.addPhoneNumber(this.phoneNumberForm.value.phoneNumber!, this.uuid).subscribe({
            next: ()=>{
              this.alertService.addAlert('info','Sign in again');
              this.loadingService.hide();
              this.authService.signOutNoRoute();
              this.currentStep = 1;
              this.verificationCodeForm.reset();
            }
          });
        }else {
          this.alertService.addAlert('error','An error occurred! Refresh the page and try again');
          this.loadingService.hide();
        }
      }).catch((err)=>{
        this.alertService.addAlert('error','An error occurred! Refresh the page and try again');
        console.error(err.message)
        this.loadingService.hide();
      });
    }else {
      this.authService.resolveMFASignIn(this.verificationCodeForm.value.verificationCode!)
        .then( async (userCredential)=>{
        this.authService.setUserData(userCredential.user);
        this.authService.token = await userCredential.user.getIdToken(true);
        this.loadingService.hide();
        this.router.navigate(['dashboard']);
      })
        .catch((err)=>{
          if(err.message.includes('invalid-verification-code')){
            this.alertService.addAlert('error','Wrong code! Refresh and start over');
          }else{
            this.alertService.addAlert('error','An error occurred! Refresh the page and try again');
            console.error(err.message);
          }
          this.loadingService.hide();
        });
    }
  }

  showHidePassword() {
    if(!this.showPass) {
      this.showPass = true;
    }
    else {
      this.showPass = false;
    }
  }
}
