<div id="publicProfileDivId">
  <div class="wrap-without-sidebar bg-light">
    <!-- Top Nav -->
    <nav class="sb-topnav navbar navbar-expand">
      <!-- Logo Image -->
      <a class="navbar-brand" href="https://veriez.com"><img src="assets/img/veriez-logo.png" alt="Logo" width="98" height="22"></a>
    </nav>
    <!-- Main Content Area -->
    <main>
      <div class="container custom-container">
        <div class="content-wrapper-top">
          <div class="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
            <div class="col-md-4 col-12 d-flex align-items-center flex-shrink-0 me-2">
              <div class="flex-shrink-0 me-4 pe-2">
                <img class="rounded-circle" [src]="(customerAccount && customerAccount.customerProfileInfoView && customerAccount.customerProfileInfoView.photoUrl) || 'assets/img/user-profile.jpg'" alt="User" width="115" height="115">
                <img *ngIf="verificationDetail && verificationDetail.photoVerified"
                     src="assets/img/verified-icon.svg" alt="Verified" placement="top" ngbTooltip="Verified By {{verificationDetail.photoVerifiedBy}} on {{verificationDetail.photoVerifiedTimestamp | date:'medium'}}">
                <img *ngIf="!(verificationDetail && verificationDetail.photoVerified)"
                     src="assets/img/x-square.svg" alt="Verified" placement="top" ngbTooltip="Not Verified">
              </div>
              <h2 *ngIf="customerAccount && customerAccount.customerContactInfoView">{{customerAccount.customerContactInfoView.firstName}} {{customerAccount.customerContactInfoView.lastName}}</h2>
              <img *ngIf="verificationDetail && verificationDetail.nameVerified"
                   src="assets/img/verified-icon.svg" alt="Verified" placement="top" ngbTooltip="Verified By {{verificationDetail.nameVerifiedBy}} on {{verificationDetail.nameVerifiedTimestamp | date:'medium'}}">
              <img *ngIf="!(verificationDetail && verificationDetail.nameVerified)"
                   src="assets/img/x-square.svg" alt="Verified" placement="top" ngbTooltip="Not Verified">
            </div>
            <div class="col-md-4 col-12 d-flex align-items-center flex-shrink-0 me-2">
              <div *ngIf="qrCodeUrl">
                <h5>Public Profile Page</h5>
                <img [src]="qrCodeUrl" alt="QR Code" />
              </div>
            </div>
            <ul class="col-md-6 col-12 flex-shrink-0 row-gap-2 d-flex flex-column">
              <li><strong>Age: </strong>{{age}}
                <img *ngIf="verificationDetail && verificationDetail.birthDateVerified"
                     placement="top" ngbTooltip="Verified By {{verificationDetail.birthDateVerifiedBy}} on {{verificationDetail.birthDateVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                <img *ngIf="!(verificationDetail && verificationDetail.birthDateVerified)"
                     src="assets/img/x-square.svg" alt="Verified" placement="top" ngbTooltip="Not Verified">
              </li>
              <li><strong>Gender: </strong>{{customerAccount?.customerProfileInfoView?.gender}}
                <img *ngIf="verificationDetail && verificationDetail.genderVerified"
                     placement="top" ngbTooltip="Verified By {{verificationDetail.genderVerifiedBy}} on {{verificationDetail.genderVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                <img *ngIf="!(verificationDetail && verificationDetail.genderVerified)"
                     src="assets/img/x-square.svg" alt="Verified" placement="top" ngbTooltip="Not Verified">
              </li>
              <li><strong>Location: </strong>{{customerAccount?.customerContactInfoView?.city}}, {{customerAccount?.customerContactInfoView?.state}}
                <img *ngIf="verificationDetail && verificationDetail.addressVerified"
                     placement="top" ngbTooltip="Verified By {{verificationDetail.addressVerifiedBy}} on {{verificationDetail.addressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                <img *ngIf="!(verificationDetail && verificationDetail.addressVerified)"
                     src="assets/img/x-square.svg" alt="Verified" placement="top" ngbTooltip="Not Verified">
              </li>
            </ul>
          </div>
        </div>
        <p>Legend:</p>
        <p>
          <img placement="top" ngbTooltip="Verified" src="assets/img/verified-icon.svg" alt="Verified">
          Successfully verified
        </p>
        <p>
          <img placement="top" ngbTooltip="Not Verified" src="assets/img/x-square.svg" alt="Not Verified">
          Not verified
        </p>
      </div>
    </main>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="downloadScreenshot()">Download Profile Picture</button>
    </div>
  </div>
</div>
<app-footer></app-footer>
