import {Injectable} from '@angular/core';
import {CustomerAccount} from "../models/CustomerAccount";
import {from, Observable, switchMap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CustomerSharingLink} from "../models/CustomerSharingLink";
import {CustomerProfileInfo} from "../models/CustomerProfileInfo";
import {CustomerContactInfo} from "../models/CustomerContactInfo";
import {CustomerEmploymentInfo} from "../models/CustomerEmploymentInfo";
import {CustomerIdentityInfo} from "../models/CustomerIdentityInfo";
import {CustomerVerificationInfo} from "../models/CustomerVerificationInfo";
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";
import {CustomerEducationInfo} from "../models/CustomerEducationInfo";
import {CheckrPackage} from "../models/CheckrPackage";

@Injectable({
  providedIn: 'root'
})
export class WsService {
  private apiUrl = environment.API_URL;
  private customerUrl = this.apiUrl+'customer/';
  private utilsUrl = this.apiUrl+'utils/';
  private customerProfileUrl = this.apiUrl+'customer-profile/';
  private customerContactUrl = this.apiUrl+'customer-contact/';
  private customerEmploymentUrl = this.apiUrl+'customer-employment/';
  private customerEducationUrl = this.apiUrl+'customer-education/';
  private customerIdentityUrl = this.apiUrl+'customer-identity/';
  private customerMarketingUrl = this.apiUrl+'customer-marketing/';
  private customerVerificationUrl = this.apiUrl+'customer-verification/';
  private emailVerificationUrl = this.apiUrl+'customer-verification/email';
  private customerShareableLinkUrl = this.apiUrl+'share/';
  private customerPublicProfileLinkUrl = this.apiUrl+'public/';
  private captchaVerificationUrl = this.apiUrl+'captcha/validate-captcha';
  private identityVerificationUrl = this.apiUrl+'verification/';
  customerData?: CustomerAccount;

  constructor(private http: HttpClient, private auth: AuthService) { }

  getAllCustomerAccountInfo(email:string): Observable<CustomerAccount> {
    return from(this.auth.idToken$).pipe(
      switchMap(token =>{
        const headers = { 'Authorization': 'Bearer ' + token };
        return this.http.get<CustomerAccount>(this.customerUrl + 'all?email=' + email, {headers : headers});
      })
    )
  }

  sendEmailVerificationLink(email: string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        let payload={
          email:email
        }
        return this.http.post<string>(this.customerUrl + 'send-email-verification', JSON.stringify(payload), {responseType: 'json',headers});
      })
    )
  }

  sendResetPasswordLink(email: string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json' };
        let payload={
          email:email
        }
        return this.http.post<string>(this.customerUrl + 'send-email-password-reset', JSON.stringify(payload), {responseType: 'json',headers});
      })
    )
  }

  addNewShareableLink(id: number, sharing : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<CustomerSharingLink>(this.customerShareableLinkUrl + '?id=' + id, JSON.stringify(sharing), {responseType: 'json',headers});
      })
    )
  }

  addPhoneNumber(phoneNumber: string, uuid: string){
    let payload = {
      phoneNumber: phoneNumber,
      countryCode: '+1'
    }
    return this.updateCustomerContact(uuid, payload);
  }

  verifyCaptcha(token: string){
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<boolean>(this.captchaVerificationUrl, JSON.stringify(token), {responseType: 'json',headers});
  }

  addNewCustomer(customer : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<CustomerAccount>(this.customerUrl,JSON.stringify(customer), {responseType: 'json',headers});
      })
    )
  }

  updateCustomer(customer: any, uuid: string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.put<CustomerAccount>(this.customerUrl+uuid,JSON.stringify(customer), {responseType: 'json',headers});
      })
    )
  }

  getCustomerProfile(uuid : string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Authorization': 'Bearer ' + token };
        return this.http.get<CustomerProfileInfo>(this.customerProfileUrl + '?uuid=' + uuid, {headers : headers});
      })
    )
  }

  addCustomerProfile(uuid : string, profile : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<CustomerProfileInfo>(this.customerProfileUrl + '?uuid=' + uuid,JSON.stringify(profile), {responseType: 'json',headers});
      })
    )
  }

  updateCustomerProfile(uuid : string, profile : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.put<CustomerProfileInfo>(this.customerProfileUrl + '?uuid=' + uuid,JSON.stringify(profile), {responseType: 'json',headers});
      })
    )
  }

  getCustomerContact(uuid : string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Authorization': 'Bearer ' + token };
        return this.http.get<CustomerContactInfo>(this.customerContactUrl + '?uuid=' + uuid, {headers: headers});
      })
    )
  }

  addCustomerContact(uuid : string, contact : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<CustomerContactInfo>(this.customerContactUrl + '?uuid=' + uuid,JSON.stringify(contact), {responseType: 'json',headers});
      })
    )
  }

  updateCustomerContact(uuid : string, contact : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.put<CustomerContactInfo>(this.customerContactUrl + '?uuid=' + uuid,JSON.stringify(contact), {responseType: 'json',headers});
      })
    )
  }

  getCustomerEmployment(uuid : string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Authorization': 'Bearer ' + token };
        return this.http.get<CustomerEmploymentInfo>(this.customerEmploymentUrl + '?uuid=' + uuid, {headers: headers});
      })
    )
  }

  addCustomerEmployment(uuid : string, employment : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<CustomerEmploymentInfo>(this.customerEmploymentUrl + '?uuid=' + uuid,JSON.stringify(employment), {responseType: 'json',headers});
      })
    )
  }

  updateCustomerEmployment(uuid : string, employment : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.put<CustomerEmploymentInfo>(this.customerEmploymentUrl + '?uuid=' + uuid,JSON.stringify(employment), {responseType: 'json',headers});
      })
    )
  }

  getCustomerEducation(uuid : string){}

  addCustomerEducation(uuid : string, education : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<CustomerEducationInfo>(this.customerEducationUrl + '?uuid=' + uuid,JSON.stringify(education), {responseType: 'json',headers});
      })
    )
  }

  updateCustomerEducation(uuid : string, education : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.put<CustomerEducationInfo>(this.customerEducationUrl + '?uuid=' + uuid,JSON.stringify(education), {responseType: 'json',headers});
      })
    )
  }

  addCustomerIdentity(uuid : string, identity : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<CustomerIdentityInfo>(this.customerIdentityUrl + '?uuid=' + uuid,JSON.stringify(identity), {responseType: 'json',headers});
      })
    )
  }

  addCustomerMarketing(uuid : string, description : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<any>(this.customerMarketingUrl + '?uuid=' + uuid,JSON.stringify(description), {responseType: 'json',headers});
      })
    )
  }

  updateCustomerIdentity(uuid : string, identity : any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.put<CustomerIdentityInfo>(this.customerIdentityUrl + '?uuid=' + uuid,JSON.stringify(identity), {responseType: 'json',headers});
      })
    )
  }

  getCustomerVerification(uuid : string){
    return this.http.get<CustomerVerificationInfo>(this.customerVerificationUrl + '?uuid=' + uuid);
  }

  updateCustomerVerification(uuid : string, verification: any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.put<CustomerVerificationInfo>(this.customerVerificationUrl + '?uuid=' + uuid,JSON.stringify(verification), {responseType: 'json',headers});
      })
    )
  }

  updateEmailVerification(uuid : string, verification: any){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.put<CustomerVerificationInfo>(this.emailVerificationUrl + '?uuid=' + uuid,JSON.stringify(verification), {responseType: 'json',headers});
      })
    )
  }

  getProfileFromURL(url: string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Authorization': 'Bearer ' + token };
        return this.http.get<CustomerAccount>(this.customerShareableLinkUrl + url, {headers: headers});
      })
    )
  }

  getProfileFromURLPin(url: string, payload: any){
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<CustomerAccount>(this.customerShareableLinkUrl + url,JSON.stringify(payload) , {headers: headers});
  }

  initialVerification(uuid: string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<string>(this.identityVerificationUrl + '?uuid=' + uuid,JSON.stringify("") , {headers: headers});
      })
    )
  }

  submitBackground(uuid: string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        return this.http.post<string>(this.identityVerificationUrl + 'submit-background' + '?uuid=' + uuid,JSON.stringify("") , {headers: headers});
      })
    )
  }

  getPublicProfile(url: string){
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<CustomerAccount>(this.customerPublicProfileLinkUrl + url, {headers: headers});
  }

  getStates(){
    return this.http.get<[Object]>('assets/us-states.json')
  }

  getCountryCodes(){
    return this.http.get<[Object]>('assets/country-codes.json')
  }

  getInstitutionTypes(){
    return this.http.get<[Object]>('assets/institution-types.json')
  }

  uploadPhoto(file: Blob, uuid : string) {
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const formData: FormData = new FormData();
        const headers = { 'Authorization': 'Bearer ' + token };
        formData.append('file', file, 'profile-cropped.png');
        return this.http.post(this.customerProfileUrl + 'picture/upload' + '?uuid=' + uuid, formData, {headers: headers});
      })
    )
  }

  uploadDocument(file: File, uuid : string) {
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const formData: FormData = new FormData();
        const headers = { 'Authorization': 'Bearer ' + token };
        formData.append('file', file, file.name);
        return this.http.post(this.customerVerificationUrl + 'document' + '?uuid=' + uuid, formData, {headers: headers});
      })
    )
  }

  orderBackgroundCheck(plan: string, uuid: string){
    return from(this.auth.getJwtToken()).pipe(
      switchMap(token =>{
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token };
        let payload = {uuid : uuid, background_plan:plan}
        return this.http.post<string>(this.customerUrl + 'order/background-check', JSON.stringify(payload), {headers: headers});
      })
    )
  }

  getCheckrPackages(){
    const headers = { 'Content-Type': 'application/json'};
    return this.http.get<CheckrPackage[]>(this.utilsUrl+"checkr/packages", {headers: headers});
  }
}
