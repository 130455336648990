<div class="wrap-without-sidebar bg-light">
  <!-- Top Nav -->
  <nav class="sb-topnav navbar navbar-expand">
    <!-- Logo Image -->
    <a class="navbar-brand" href="https://veriez.com"><img src="assets/img/veriez-logo.png" alt="Logo" width="98" height="22"></a>
  </nav>
  <!-- Main Content Area -->
  <main>
    <div class="container" *ngIf="!isVerified">
      <div class="authentication">
        <div class="card-body">
          <div class="text-center mb-4">
            <a href="https://veriez.com"><img class="mb-5" src="assets/img/veriez-logo.png" alt="VeriEZ" width="138" height="31"></a>
            <h6 class="mb-0">Enter Pin Code</h6>
          </div>
          <form [formGroup]="verificationForm" (ngSubmit)="onSubmit()">
            <div class="form-column mb-3">
              <label for="enterPin" class="col-form-label">Enter PIN</label>
              <input type="password" formControlName="pin" id="enterPin" class="form-control" placeholder="Enter PIN Code">
            </div>
            <button type="submit" class="btn btn-primary w-100" href="shared-profile-preview.html" [disabled]="verificationForm.invalid">Enter Pin Code</button>
            <div *ngIf="verificationError" class="error-message">
              Incorrect PIN! Please try again.
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container custom-container" *ngIf="isVerified">
      <div class="content-wrapper-top">
        <div class="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
          <div class="col-md-4 col-12 d-flex align-items-center flex-shrink-0 me-2">
            <div class="flex-shrink-0 me-4 pe-2">
              <img class="rounded-circle" [src]="(customerAccount && customerAccount.customerProfileInfoView && customerAccount.customerProfileInfoView.photoUrl) || 'assets/img/user-profile.jpg'" alt="User" width="115" height="115">
            </div>
            <h2 *ngIf="customerAccount && customerAccount.customerContactInfoView">{{customerAccount.customerContactInfoView.firstName}} {{customerAccount.customerContactInfoView.lastName}}</h2>
            <img *ngIf="verificationDetail && verificationDetail.nameVerified"
                 src="assets/img/verified-icon.svg" alt="Verified" placement="top" ngbTooltip="Verified By {{verificationDetail.nameVerifiedBy}} on {{verificationDetail.nameVerifiedTimestamp | date:'medium'}}">
          </div>
          <ul class="col-md-4 col-12 flex-shrink-0 row-gap-2 d-flex flex-column" *ngIf="customerAccount && customerAccount.customerContactInfoView">
            <li *ngIf="customerAccount.email">
              {{customerAccount.email}}
              <img *ngIf="verificationDetail && verificationDetail.emailVerified"
                   placement="top" ngbTooltip="Verified By {{verificationDetail.emailVerifiedBy}} on {{verificationDetail.emailVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
            </li>
            <li *ngIf="customerAccount.customerContactInfoView.phoneNumber">
              {{customerAccount.customerContactInfoView.countryCode}} {{customerAccount.customerContactInfoView.phoneNumber}}
              <img *ngIf="verificationDetail && verificationDetail.phoneNumberVerified"
                   placement="top" ngbTooltip="Verified By {{verificationDetail.phoneNumberVerifiedBy}} on {{verificationDetail.phoneNumberVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
            </li>
            <li *ngIf="customerAccount.customerContactInfoView.address1">{{customerAccount.customerContactInfoView.address1}}</li>
            <li *ngIf="customerAccount.customerContactInfoView.address2">{{customerAccount.customerContactInfoView.address2}}</li>
            <li *ngIf="customerAccount.customerContactInfoView.city && customerAccount.customerContactInfoView.state && customerAccount.customerContactInfoView.zipCode">{{customerAccount.customerContactInfoView.city}} {{customerAccount.customerContactInfoView.state}}, {{customerAccount.customerContactInfoView.zipCode}}
              <img *ngIf="verificationDetail && verificationDetail.addressVerified"
                   placement="top" ngbTooltip="Verified By {{verificationDetail.addressVerifiedBy}} on {{verificationDetail.addressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
            </li>
          </ul>
        </div>
      </div>
      <div class="content-wrapper-middle">
        <div class="row g-md-5">
          <div class="col-md-6">
            <div class="info-table" *ngIf="customerAccount && customerAccount.customerProfileInfoView
            && (customerAccount.customerProfileInfoView.dateOfBirth || customerAccount.customerProfileInfoView.weight || customerAccount.customerProfileInfoView.height
             || customerAccount.customerProfileInfoView.gender || customerAccount.customerProfileInfoView.race || customerAccount.customerProfileInfoView.relationshipStatus)">
              <h5 class="mb-3">Profile Info</h5>
              <div class="table-responsive">
                <table class="table table-borderless transparent-table mb-0">
                  <tbody>
                  <tr *ngIf="customerAccount.customerProfileInfoView.dateOfBirth">
                    <td>Date of Birth:</td>
                    <td>{{customerAccount.customerProfileInfoView.dateOfBirth | date: 'longDate'}}
                      <img *ngIf="verificationDetail && verificationDetail.birthDateVerified"
                           placement="top" ngbTooltip="Verified By {{verificationDetail.birthDateVerifiedBy}} on {{verificationDetail.birthDateVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                    </td>
                  </tr>
                  <tr *ngIf="customerAccount.customerProfileInfoView.height">
                    <td>Height:</td>
                    <td>{{customerAccount.customerProfileInfoView.height | convertHeight}}
                      <img *ngIf="verificationDetail && verificationDetail.heightVerified"
                           placement="top" ngbTooltip="Verified By {{verificationDetail.heightVerifiedBy}} on {{verificationDetail.heightVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                    </td>
                  </tr>
                  <tr *ngIf="customerAccount.customerProfileInfoView.weight">
                    <td>Weight (lb):</td>
                    <td>{{customerAccount.customerProfileInfoView.weight}}
                      <img *ngIf="verificationDetail && verificationDetail.weightVerified"
                           placement="top" ngbTooltip="Verified By {{verificationDetail.weightVerifiedBy}} on {{verificationDetail.weightVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                    </td>
                  </tr>
                  <tr *ngIf="customerAccount.customerProfileInfoView.gender">
                    <td>Gender:</td>
                    <td>{{customerAccount.customerProfileInfoView.gender}}
                      <img *ngIf="verificationDetail && verificationDetail.genderVerified"
                           placement="top" ngbTooltip="Verified By {{verificationDetail.genderVerifiedBy}} on {{verificationDetail.genderVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                    </td>
                  </tr>
                  <tr *ngIf="customerAccount.customerProfileInfoView.race">
                    <td>Race:</td>
                    <td>{{customerAccount.customerProfileInfoView.race}}
                      <img *ngIf="verificationDetail && verificationDetail.raceVerified"
                           placement="top" ngbTooltip="Verified By {{verificationDetail.raceVerifiedBy}} on {{verificationDetail.raceVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                    </td>
                  </tr>
                  <tr *ngIf="customerAccount.customerProfileInfoView.relationshipStatus">
                    <td>Relationship Status:</td>
                    <td>{{customerAccount.customerProfileInfoView.relationshipStatus}}
                      <img *ngIf="verificationDetail && verificationDetail.relationshipStatusVerified"
                           placement="top" ngbTooltip="Verified By {{verificationDetail.relationshipStatusVerifiedBy}} on {{verificationDetail.relationshipStatusVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr class="my-4 py-1">
            <div class="info-table" *ngIf="customerAccount && customerAccount.customerBackgroundInfoView">
              <h5 class="mb-3">Background Info</h5>
              <div class="table-responsive">
                <table class="table table-borderless transparent-table mb-0">
                  <tbody>
                  <tr>
                    <td>SSN Trace:</td>
                    <td>{{customerAccount.customerBackgroundInfoView.ssnTrace | uppercase}}</td>
                  </tr>
                  <tr>
                    <td>Sex Offender Registry:</td>
                    <td>{{customerAccount.customerBackgroundInfoView.sexOffenderRegistry | uppercase}}</td>
                  </tr>
                  <tr>
                    <td>Global Watchlist:</td>
                    <td>{{customerAccount.customerBackgroundInfoView.globalWatchlistRecord | uppercase}}</td>
                  </tr>
                  <tr>
                    <td>National Watchlist:</td>
                    <td>{{customerAccount.customerBackgroundInfoView.nationalWatchlistRecord | uppercase}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr class="my-4 py-1 d-md-none">
          </div>
          <div class="col-md-6">
            <div class="info-table" *ngIf="customerAccount && customerAccount.customerEmploymentInfoView">
              <h5 class="mb-3">Employment Info</h5>
              <div class="table-responsive">
                <table class="table table-borderless transparent-table mb-0">
                  <tbody>
                  <tr *ngIf="customerAccount.customerEmploymentInfoView.customerEmployerName">
                    <td>Employer Name:</td>
                    <td>{{customerAccount.customerEmploymentInfoView.customerEmployerName}}</td>
                  </tr>
                  <tr *ngIf="customerAccount.customerEmploymentInfoView.customerEmployerPhoneNumber">
                    <td>Employer Phone Number:</td>
                    <td>+1 {{customerAccount.customerEmploymentInfoView.customerEmployerPhoneNumber}}</td>
                  </tr>
                  <tr *ngIf="customerAccount.customerEmploymentInfoView.customerEmploymentPosition">
                    <td>Employment Position:</td>
                    <td>{{customerAccount.customerEmploymentInfoView.customerEmploymentPosition}}</td>
                  </tr>
                  <tr *ngIf="customerAccount.customerEmploymentInfoView.customerEmployerAddress1">
                    <td>Employer Address 1:</td>
                    <td>{{customerAccount.customerEmploymentInfoView.customerEmployerAddress1}}</td>
                  </tr>
                  <tr *ngIf="customerAccount.customerEmploymentInfoView.customerEmployerAddress2">
                    <td>Employer Address 2:</td>
                    <td>{{customerAccount.customerEmploymentInfoView.customerEmployerAddress2}}</td>
                  </tr>
                  <tr *ngIf="customerAccount.customerEmploymentInfoView.customerEmployerCity">
                    <td>Employer City:</td>
                    <td>{{customerAccount.customerEmploymentInfoView.customerEmployerCity}}</td>
                  </tr>
                  <tr *ngIf="customerAccount.customerEmploymentInfoView.customerEmployerState">
                    <td>Employer State:</td>
                    <td>{{customerAccount.customerEmploymentInfoView.customerEmployerState}}</td>
                  </tr>
                  <tr *ngIf="customerAccount.customerEmploymentInfoView.customerEmployerZipCode">
                    <td>Employer Zip:</td>
                    <td>{{customerAccount.customerEmploymentInfoView.customerEmployerZipCode}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr class="my-4 py-1">
            <div class="info-table" *ngIf="customerAccount && customerAccount.customerEducationInfoView">
              <h5 class="mb-3">Education Info</h5>
              <div class="table-responsive">
                <table class="table table-borderless transparent-table mb-0">
                  <tbody>
                  <tr>
                    <td>School:</td>
                    <td>{{customerAccount.customerEducationInfoView.institutionName}}</td>
                  </tr>
                  <tr>
                    <td>Degree:</td>
                    <td>{{customerAccount.customerEducationInfoView.degree}}</td>
                  </tr>
                  <tr>
                    <td>Graduated:</td>
                    <td>{{customerAccount.customerEducationInfoView.graduated | booleanToYesNo}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>
        <img placement="top" ngbTooltip="Verified" src="assets/img/verified-icon.svg" alt="Verified">
        Successfully verified
      </p>
    </div>
  </main>
  <app-footer></app-footer>
</div>
