<div class="wrap-without-sidebar bg-light min-vh-100 top-0">
  <main>
    <div class="container">
      <div class="authentication">
        <div class="card-body">
          <div class="text-center mb-4">
            <a routerLink="/"><img class="mb-5" src="assets/img/veriez-logo.png" alt="VeriEZ" width="138" height="31"></a>
            <h6 class="mb-0">Forgot Password</h6>
          </div>
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" *ngIf="!isEmailSent">
            <div class="form-column mb-3">
              <label for="email" class="col-form-label">Email Address</label>
              <input type="email" class="form-control" id="email" name="email" formControlName="email" placeholder="Enter Email Address">
              <div *ngIf="forgotPasswordForm.get('email')?.errors && forgotPasswordForm.get('email')?.touched">
                <small class="text-danger" *ngIf="forgotPasswordForm.get('email')?.errors?.['required']">
                  Email is required.
                </small>
                <small class="text-danger" *ngIf="forgotPasswordForm.get('email')?.errors?.['email']">
                  Enter a valid email.
                </small>
              </div>
            </div>
            <button type="submit" class="btn btn-primary w-100 mb-4" [disabled]="!forgotPasswordForm.valid">Send Password Recovery Link</button>
            <p class="text-center mb-0"><strong>Back to <a routerLink="/sign-in">Sign In</a></strong></p>
          </form>
          <div class="form-column mb-3" *ngIf="isEmailSent">
            <p class="text-center">We have sent a password reset email to <strong>{{email}}</strong>.</p>
            <p class="text-center">Please check your email and click on the link to reset your password.</p>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
