export const environment = {
  firebase: {
    apiKey: "AIzaSyCG8Nj_O7bBiBQZQyFZZpE7ixP3BKpGrjk",
    authDomain: "veriez-staging.firebaseapp.com",
    projectId: "veriez-staging",
    storageBucket: "veriez-staging.appspot.com",
    messagingSenderId: "226902986636",
    appId: "1:226902986636:web:ce8ec0af9e13b5d41590a9"
  },
  production: false,
  API_URL: 'https://api-dev.veriez.com/v1/',
};
