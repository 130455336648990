import {Component, OnInit} from '@angular/core';
import {CustomerAccount} from "../../models/CustomerAccount";
import {ActivatedRoute} from "@angular/router";
import {WsService} from "../../services/ws.service";
import {firstValueFrom, from, lastValueFrom, switchMap} from "rxjs";
import {LoadingService} from "../../services/loading.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomerVerificationDetail} from "../../models/CustomerVerificationDetail";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-shared-profile',
  templateUrl: './shared-profile.component.html',
  styleUrls: ['./shared-profile.component.css']
})
export class SharedProfileComponent implements OnInit{
  customerAccount?: CustomerAccount;
  link_url: string = '';
  isVerified: boolean = false;
  verificationError: boolean = false;
  // Create the form group
  verificationForm = this.fb.group({
    pin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]] // Assuming PIN is between 4 to 6 digits
  });
  verificationDetail: CustomerVerificationDetail = <CustomerVerificationDetail>{};

  constructor(private route: ActivatedRoute, private customerService:WsService,
              private loadingService: LoadingService, private fb: FormBuilder,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.link_url = params['link_url'];
    })
  }

  async onSubmit() {
    this.loadingService.show();
    if (this.verificationForm.valid) {
      try {
        const pinValue = this.verificationForm.get('pin')!.value;
        // Replace with your backend endpoint and expected payload
        const payload = {
          "pin":pinValue
        }
        this.customerAccount = await firstValueFrom(this.customerService.getProfileFromURLPin(this.link_url, payload));

        if (this.customerAccount) {
          this.isVerified = true;
          this.verificationError = false;
          this.alertService.addAlert('success',"PIN Verified!");
          if(this.customerAccount.customerVerificationInfoViews){
            this.customerAccount.customerVerificationInfoViews.forEach((view)=>{
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'PHOTO' && view.verified){
                this.verificationDetail.photoVerified = true;
                this.verificationDetail.photoVerifiedBy = view.verifiedBy;
                this.verificationDetail.photoVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'BIRTHDATE' && view.verified){
                this.verificationDetail.birthDateVerified = true;
                this.verificationDetail.birthDateVerifiedBy = view.verifiedBy;
                this.verificationDetail.birthDateVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'HEIGHT' && view.verified){
                this.verificationDetail.heightVerified = true;
                this.verificationDetail.heightVerifiedBy = view.verifiedBy;
                this.verificationDetail.heightVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'WEIGHT' && view.verified){
                this.verificationDetail.weightVerified = true;
                this.verificationDetail.weightVerifiedBy = view.verifiedBy;
                this.verificationDetail.weightVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'GENDER' && view.verified){
                this.verificationDetail.genderVerified = true;
                this.verificationDetail.genderVerifiedBy = view.verifiedBy;
                this.verificationDetail.genderVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'RACE' && view.verified){
                this.verificationDetail.raceVerified = true;
                this.verificationDetail.raceVerifiedBy = view.verifiedBy;
                this.verificationDetail.raceVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'RELATIONSHIP' && view.verified){
                this.verificationDetail.relationshipStatusVerified = true;
                this.verificationDetail.relationshipStatusVerifiedBy = view.verifiedBy;
                this.verificationDetail.relationshipStatusVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'NAME' && view.verified){
                this.verificationDetail.nameVerified = true;
                this.verificationDetail.nameVerifiedBy = view.verifiedBy;
                this.verificationDetail.nameVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'ADDRESS' && view.verified){
                this.verificationDetail.addressVerified = true;
                this.verificationDetail.addressVerifiedBy = view.verifiedBy;
                this.verificationDetail.addressVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'PHONE' && view.verified){
                this.verificationDetail.phoneNumberVerified = true;
                this.verificationDetail.phoneNumberVerifiedBy = view.verifiedBy;
                this.verificationDetail.phoneNumberVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'EMAIL' && view.verified){
                this.verificationDetail.emailVerified = true;
                this.verificationDetail.emailVerifiedBy = view.verifiedBy;
                this.verificationDetail.emailVerifiedTimestamp = view.verificationTimestamp;
              }
            })
          }
        } else {
          this.verificationError = true;
          this.alertService.addAlert('info',"Invalid PIN");
        }
      } catch (error) {
        console.error(error);
        this.verificationError = true;
        this.alertService.addAlert('error', "Error happened while verifying PIN");
      }
    }
    this.loadingService.hide();
  }
}
