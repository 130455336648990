<div class="content-wrapper-top">
  <h3>Verification Center</h3>
  <p class="mb-4">Review your Verification Steps Here</p>

  <div class="d-flex align-items-center flex-wrap flex-sm-nowrap row-gap-3">
    <ul class="list-group">
      <li *ngFor="let task of tasks" class="list-group-item d-flex justify-content-between align-items-center">
        <span [class.text-decoration-line-through]="task.completed">{{ task.name }}</span>
        <span *ngIf="task.completed" class="text-success">&#10004;</span>
      </li>
    </ul>
  </div>

</div>
<div class="content-wrapper-middle">
  <h6 class="mb-0 gray-color fw-medium">Upload more documentation!</h6>
  <h5>Profile Info</h5>
  <p>Upload your BMI docs to verify your height and weight</p>
  <input type="file" accept="pdf/*" (change)="onFileChange($event)">
  <hr>
  <h5>Contact Info</h5>
  <p>Upload a bill to verify address</p>
  <input type="file" accept="pdf/*" (change)="onFileChange($event)">
  <hr>
  <h5>Employment Info</h5>
  <p>Upload your pay-stub</p>
  <input type="file" accept="pdf/*" (change)="onFileChange($event)">
  <hr>
  <h5>Education Info</h5>
  <p>Upload your Diploma/Degree</p>
  <input type="file" accept="pdf/*" (change)="onFileChange($event)">
</div>
