import { inject, Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword, idToken, multiFactor,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword, applyActionCode, fetchSignInMethodsForEmail,
  updatePassword, confirmPasswordReset,
  User,
  user, RecaptchaVerifier, PhoneAuthProvider, PhoneMultiFactorGenerator, getMultiFactorResolver, MultiFactorResolver
} from "@angular/fire/auth";
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth:Auth = inject(Auth);
  user: any;
  user$ = user(this.auth);
  userData: any;
  token: string = ''
  idToken$ = idToken(this.auth);
  recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
    "size": "invisible",
    "callback": function(response: any) {
      // reCAPTCHA solved, you can proceed with
      // phoneAuthProvider.verifyPhoneNumber(...).
      // onSolvedRecaptcha();
    }
  }, this.auth);
  phoneAuthProvider:PhoneAuthProvider|undefined = undefined;
  verificationId: string | undefined;
  resolver: MultiFactorResolver | null = null;
  constructor(public router: Router) {
    this.auth.onAuthStateChanged(user => {
      if(user){
        this.user = user;
      }
    });
  }

  signIn(email:string, password:string){
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  async resolveMFA(error: any){
    let isResolved: boolean = false
    this.resolver = getMultiFactorResolver(this.auth, error);
    let selectedIndex = 0;
    if (this.resolver.hints[selectedIndex].factorId ===
      PhoneMultiFactorGenerator.FACTOR_ID) {
      // User selected a phone second factor.
      const phoneInfoOptions = {
        multiFactorHint: this.resolver.hints[selectedIndex],
        session: this.resolver.session
      };
      // Send SMS verification code.
      if (!this.phoneAuthProvider) {
        this.phoneAuthProvider = new PhoneAuthProvider(this.auth);
      }
      this.verificationId = await this.phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier);
      if(this.verificationId){
        isResolved = true
      }
    } else {
      // Unsupported second factor.
    }
    return isResolved
  }

  resolveMFASignIn(verificationCode: string){
    const cred = PhoneAuthProvider.credential(this.verificationId!, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    return this.resolver!.resolveSignIn(multiFactorAssertion);
  }

  signUp(email: string, password: string){
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  checkAccountProviders(email: string){
    return fetchSignInMethodsForEmail(this.auth, email)
  }

  async sendMFACode(phoneNumber: string) {
    let isCodeSent: boolean = false;
    const multiFactorSession = await multiFactor(this.user).getSession();
    // Specify the phone number and pass the MFA session.
    const phoneInfoOptions = {
      phoneNumber: phoneNumber,
      session: multiFactorSession
    };
    if (!this.phoneAuthProvider) {
      this.phoneAuthProvider = new PhoneAuthProvider(this.auth);
    }
    this.verificationId = await this.phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier);
    if(this.verificationId){
      isCodeSent = true;
    }else {
      this.recaptchaVerifier.clear();
    }
    return isCodeSent;
  }

  async completeEnrollMFA(code:string){
    const cred = PhoneAuthProvider.credential(this.verificationId!, code);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    await multiFactor(this.user).enroll(multiFactorAssertion).then(()=>{
    }).catch((error)=>{
      console.error(error.message);
      return false;
    });
    return true;
  }

  sendVerificationMail() {
    return sendEmailVerification(this.auth.currentUser!)
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }

  verifyEmail(oobCode: string){
    return applyActionCode(this.auth, oobCode);
  }

  confirmPasswordReset(newPassword: string, oobCode: string){
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  forgotPassword(passwordResetEmail: string) {
    return sendPasswordResetEmail(this.auth, passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  setUserData(user: any) {
    if(user){
      this.userData = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
        isNew: user.isNew
      };
      localStorage.setItem('user', JSON.stringify(this.userData));
      JSON.parse(localStorage.getItem('user')!);
    } else {
      localStorage.setItem('user', 'null');
      JSON.parse(localStorage.getItem('user')!);
    }
  }

  getUserData(){
    return JSON.parse(localStorage.getItem('user')!);
  }

  changePassword(password:string){
    updatePassword(this.userData, password).then(() => {
      // Update successful.
      console.log('Update Successful');
    }).catch((error) => {
      // An error occurred
      console.log(error);
    });
  }

  getUser(): User {
    if(!this.user){
      this.user$.subscribe(async user =>{
        this.user = await user;
      })
    }
    return this.user;
  }

  // Check if user is signed in
  isLoggedIn(): boolean {
    const user = this.auth.currentUser;
    return !!user;
  }

  async getJwtToken(): Promise<string | null> {
    const user = this.auth.currentUser;
    if (user) {
      return await user.getIdToken();
    }
    return null;
  }

  // Sign out
  async signOut() {
    await this.auth.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['sign-in']);
  }

  // Sign out to dashboard
  async signOutNoRoute() {
    await this.auth.signOut();
    localStorage.removeItem('user');
  }
}
