import {Component, HostBinding, Input} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.css'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class NotificationBannerComponent {
  @HostBinding('@fade') fade = true;
  @Input() showBanner = true;
  @Input() bannerMessage: string = '';
  @Input() url: string|undefined;

  constructor() {
    setTimeout(()=>{
      this.showBanner = false;
    }, 5000);
  }

  closeBanner() {
    this.showBanner = false;
  }
}
