<div class="wrap-without-sidebar bg-light min-vh-100 top-0">
  <main>
    <div class="container">
      <div class="authentication">
        <div class="card-body">
          <div class="text-center mb-4">
            <a routerLink="/"><img class="mb-5" src="assets/img/veriez-logo.png" alt="VeriEZ" width="138" height="31"></a>
            <h6 class="mb-0">Thank You for Registering</h6>
          </div>
          <div class="form-column mb-3" *ngIf="authService.userData as user">
            <p class="text-center">We have sent a confirmation email to <strong>{{user.email}}</strong>.</p>
            <p class="text-center">Please check your email and click on the link to verify your email address.</p>
            <div class="text-center">
              <button type="button" class="btn btn-primary" (click)="sendVerificationMail(user.email)">
                <i class="bi bi-arrow-bar-right"></i>
                Resend Verification Email
              </button>
            </div>
          </div>
          <p class="text-center">Go back to <strong><a routerLink="/sign-in">Sign In</a></strong></p>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>


