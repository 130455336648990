<div class="content-wrapper-top">
  <h3>Share Profile</h3>
  <p class="mb-4">Select contents of your profile you want to share</p>
  <button type="button" class="mt-4 btn btn-primary" style="margin-right: 10px;" (click)="generateLink(content)">Create Shareable Link</button>
  <button *ngIf="isProd" type="button" class="mt-4 btn btn-info" ngxClipboard cbContent="https://app.veriez.com/public/{{customerData.customerPublicProfileInfoView.customerPublicProfileUrl}}">Copy Public Page Link</button>
  <button *ngIf="!isProd" type="button" class="mt-4 btn btn-info" ngxClipboard cbContent="https://app-dev.veriez.com/public/{{customerData.customerPublicProfileInfoView.customerPublicProfileUrl}}">Copy Public Page Link</button>
  <button type="button" class="mt-4 btn btn-link" (click)="selectAllBoxes()" *ngIf="!allSelected">Select All</button>
  <button type="button" class="mt-4 btn btn-link" (click)="deselectAllBoxes()" *ngIf="allSelected">Deselect All</button>
</div>
<div class="content-wrapper-middle">
  <form [formGroup]="shareProfileForm" (ngSubmit)="generateLink(content)">
    <div ngbAccordion class="accordion accordion-flush d-grid info-group-wrapper row-gap-4" id="accordionShareProfile">
      <div ngbAccordionItem [collapsed]="false" class="accordion-item border-0 overflow-hidden rounded-2">
        <h2 ngbAccordionHeader class="accordion-header" id="headingProfileInfo">
          <button ngbAccordionButton class="accordion-button fs-5" type="button">Profile Info</button>
        </h2>
        <div ngbAccordionCollapse id="collapseProfileInfo" class="accordion-collapse">
          <div ngbAccordionBody class="accordion-body">
            <ng-template>
              <div class="info-group">
                <div class="row row-gap-4">
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/id-card-icon.svg" alt="Full Name">
                      <p class="small-txt fw-semibold mb-2 mt-3">Full Name</p>
                      <div class="check-card">
                        <input type="checkbox" class="form-check-inline" id="shareName" name="shareName" formControlName="shareName"><span></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/id-card-icon.svg" alt="User Photo">
                      <p class="small-txt fw-semibold mb-2 mt-3">Photo</p>
                      <div class="check-card">
                        <input type="checkbox" class="form-check-inline" id="sharePhoto" name="sharePhoto" formControlName="sharePhoto"><span></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/calendar-icon.svg" alt="Date of Birth">
                      <p class="small-txt fw-semibold mb-2 mt-3">Date of Birth</p>
                      <div class="check-card">
                        <input type="checkbox" class="form-check-inline" id="shareBirthDate" name="shareBirthDate" formControlName="shareBirthDate"><span></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/height-icon.svg" alt="Height (in)">
                      <p class="small-txt fw-semibold mb-2 mt-3">Height (in)</p>
                      <div class="check-card">
                        <input type="checkbox" class="form-check-inline" id="shareHeight" name="shareHeight" formControlName="shareHeight"><span></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/weight-icon.svg" alt="Weight (lbs)">
                      <p class="small-txt fw-semibold mb-2 mt-3">Weight (lbs)</p>
                      <div class="check-card">
                        <input type="checkbox" class="form-check-inline" id="shareWeight" name="shareWeight" formControlName="shareWeight"><span></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/gender-icon.svg" alt="Gender">
                      <p class="small-txt fw-semibold mb-2 mt-3">Gender</p>
                      <div class="check-card">
                        <input type="checkbox" class="form-check-inline" id="shareGender" name="shareGender" formControlName="shareGender"><span></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/human-race-icon.svg" alt="Race">
                      <p class="small-txt fw-semibold mb-2 mt-3">Race</p>
                      <div class="check-card">
                        <input type="checkbox" class="form-check-inline" id="shareRace" name="shareRace" formControlName="shareRace"><span></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/relationship-status-icon.svg" alt="Relationship Status">
                      <p class="small-txt fw-semibold mb-2 mt-3">Relationship Status</p>
                      <div class="check-card">
                        <input type="checkbox" class="form-check-inline" id="shareRelationshipStatus" name="shareRelationshipStatus" formControlName="shareRelationshipStatus"><span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div ngbAccordionItem [collapsed]="false" class="accordion-item border-0 overflow-hidden rounded-2">
        <h2 ngbAccordionHeader class="accordion-header" id="headingContactInfo">
          <button ngbAccordionButton class="accordion-button fs-5" type="button">Contact Info</button>
        </h2>
        <div ngbAccordionCollapse id="collapseContactInfo" class="accordion-collapse">
          <div ngbAccordionBody class="accordion-body">
            <div class="info-group">
              <div class="row row-gap-4">
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/email-icon.svg" alt="Email Address">
                    <p class="small-txt fw-semibold mb-2 mt-3">Email Address</p>
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareEmail" name="shareEmail" formControlName="shareEmail"><span></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/phone-icon.svg" alt="Phone No">
                    <p class="small-txt fw-semibold mb-2 mt-3">Phone No.</p>
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="sharePhoneNumber" name="sharePhoneNumber" formControlName="sharePhoneNumber"><span></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/address-icon.svg" alt="Full Address">
                    <p class="small-txt fw-semibold mb-2 mt-3">Full Address</p>
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareAddress" name="shareAddress" formControlName="shareAddress"><span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionItem [collapsed]="false" class="accordion-item border-0 overflow-hidden rounded-2">
        <h2 ngbAccordionHeader class="accordion-header" id="headingEmploymentInfo">
          <button ngbAccordionButton class="accordion-button fs-5" type="button">Employment Info</button>
        </h2>
        <div ngbAccordionCollapse id="collapseEmploymentInfo" class="accordion-collapse">
          <div ngbAccordionBody class="accordion-body">
            <div class="info-group">
              <div class="row row-gap-4">
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/id-card-icon.svg" alt="Employer Name">
                    <p class="small-txt fw-semibold mb-2 mt-3">Employer Name</p>
<!--                    <h6 class="mb-0">Sundance</h6>-->
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareEmployerName" name="shareEmployerName" formControlName="shareEmployerName"><span></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/phone-icon.svg" alt="Employer Phone No">
                    <p class="small-txt fw-semibold mb-2 mt-3">Employer Phone No..</p>
<!--                    <h6 class="mb-0">1234567890</h6>-->
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareEmployerPhoneNumber" name="shareEmployerPhoneNumber" formControlName="shareEmployerPhoneNumber"><span></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/human-race-icon.svg" alt="Employment Position">
                    <p class="small-txt fw-semibold mb-2 mt-3">Employment Position</p>
<!--                    <h6 class="mb-0">Software Engineer</h6>-->
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareEmploymentPosition" name="shareEmploymentPosition" formControlName="shareEmploymentPosition"><span></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/address-icon.svg" alt="Employer Address">
                    <p class="small-txt fw-semibold mb-2 mt-3">Employer Address</p>
<!--                    <h6 class="mb-0">#464, Vinewood Avenue, Los Angeles, California, 146734</h6>-->
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareEmployerAddress" name="shareEmployerAddress" formControlName="shareEmployerAddress"><span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionItem [collapsed]="false" class="accordion-item border-0 overflow-hidden rounded-2">
        <h2 ngbAccordionHeader class="accordion-header" id="headingBackgroundInfo">
          <button ngbAccordionButton class="accordion-button fs-5" type="button">Background Info</button>
        </h2>
        <div ngbAccordionCollapse id="collapseBackgroundInfo" class="accordion-collapse">
          <div ngbAccordionBody class="accordion-body">
            <div class="info-group">
              <div class="row row-gap-4">
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/id-card-icon.svg" alt="SSN Trace">
                    <p class="small-txt fw-semibold mb-2 mt-3">SSN Trace</p>
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareSSNTrace" name="shareSSNTrace" formControlName="shareSSNTrace"><span></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/id-card-icon.svg" alt="Sex Offender Registry">
                    <p class="small-txt fw-semibold mb-2 mt-3">Sex Offender Registry</p>
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareSexOffenderRegistry" name="shareSexOffenderRegistry" formControlName="shareSexOffenderRegistry"><span></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/id-card-icon.svg" alt="Global Watchlist Records">
                    <p class="small-txt fw-semibold mb-2 mt-3">Global Watchlist Records</p>
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareGlobalWatchlistRecords" name="shareGlobalWatchlistRecords" formControlName="shareGlobalWatchlistRecords"><span></span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xxl-3">
                  <div class="card-block">
                    <img src="assets/img/id-card-icon.svg" alt="National Criminal Records">
                    <p class="small-txt fw-semibold mb-2 mt-3">National Criminal Records</p>
                    <div class="check-card">
                      <input type="checkbox" class="form-check-inline" id="shareNationalWatchlistRecords" name="shareNationalWatchlistRecords" formControlName="shareNationalWatchlistRecords"><span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="mt-4 btn btn-primary">Create Shareable Link</button>
  </form>
</div>

<!-- Share Modal -->
<ng-template #content let-modal>
  <div class="modal-header px-4">
    <h4 class="modal-title" id="staticBackdropLabel">Shareable Links</h4>
  </div>
  <form action="" *ngIf="shareableLink">
    <div class="modal-body form-body px-4">
      <div class="form-column">
        <input type="text" class="form-control" value="{{getLink()}}" readonly>
        <button type="button" class="btn btn-link" ngxClipboard cbContent="{{getLink()}}"><img src="assets/img/copy-icon.svg" alt="Copy"></button>
      </div>
      <div class="form-column text-center my-4 py-2">
        <div *ngIf="qrCodeUrl">
          <img [src]="qrCodeUrl" alt="QR Code" width="250"/>
        </div>
      </div>
      <div class="form-column">
        <label for="qrPin" class="col-form-label">Pin</label>
        <input type="text" id="qrPin" class="form-control" value="{{shareableLink.pin}}" readonly>
      </div>
    </div>
    <div class="modal-footer justify-content-between border-0 px-4 pb-4 pt-0">
      <button type="button" class="btn btn-primary" ngxClipboard cbContent="{{getLink()}}">Copy Link</button>
      <button type="button" class="btn btn-outline-danger" (click)="modalService.dismissAll()">Close</button>
    </div>
  </form>
</ng-template>
