import { Component } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {WsService} from "../../services/ws.service";
import {AlertService} from "../../services/alert.service";
import {LoadingService} from "../../services/loading.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  isEmailSent: boolean = false;

  forgotPasswordForm = this.formBuilder.group({
    email: ['',[Validators.required, Validators.email]]
  });
  email: string|undefined;
  constructor(private formBuilder: FormBuilder,  private loadingService: LoadingService,
              private customerService: WsService, private alertService: AlertService) {
  }

  onSubmit(): void {
    this.sendPasswordResetLink();
  }

  sendPasswordResetLink(){
    this.loadingService.show();
    this.alertService.addAlert('info','Please wait while we email you a link!');
    this.customerService.sendResetPasswordLink(this.forgotPasswordForm.value.email!).subscribe({
      next: a=>{
        this.loadingService.hide();
        if(a && a==='SUCCESS'){
          this.email = this.forgotPasswordForm.value.email!;
          this.isEmailSent = true;
        }
      },
      error: err => {
        this.loadingService.hide();
        this.alertService.addAlert('error', 'Cannot email the reset password link')
      }
    })
  }
}
