import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null; // don't validate empty value
    }

    const hasUpperCase = /[A-Z]+/.test(value);
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);
    const isValid = hasUpperCase && hasSymbol && value.length >= 8;

    return isValid ? null : { passwordStrength: true };
  };
}
