<div class="wrap-without-sidebar bg-light min-vh-100 top-0">
  <main>
    <div class="container">
      <div class="authentication">
        <div class="card-body">
          <div class="text-center mb-4">
            <a href="https://veriez.com"><img class="mb-5" src="assets/img/veriez-logo.png" alt="VeriEZ" width="138" height="31"></a>
            <h6 class="mb-0">Sign Up</h6>
          </div>
          <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
            <div class="form-column mb-3">
              <label for="firstName" class="col-form-label">First Name</label>
              <input type="text" class="form-control" id="firstName" name="firstName" formControlName="firstName" placeholder="First Name">
              <div *ngIf="signUpForm.get('firstName')?.errors && signUpForm.get('firstName')?.touched">
                <small class="text-danger" *ngIf="signUpForm.get('firstName')?.errors?.['required']">
                  First Name is required.
                </small>
              </div>
            </div>
            <div class="form-column mb-3">
              <label for="lastName" class="col-form-label">Last Name</label>
              <input type="text" class="form-control" id="lastName" name="lastName" formControlName="lastName" placeholder="Last Name">
              <div *ngIf="signUpForm.get('lastName')?.errors && signUpForm.get('lastName')?.touched">
                <small class="text-danger" *ngIf="signUpForm.get('lastName')?.errors?.['required']">
                  Last Name is required.
                </small>
              </div>
            </div>
            <div class="form-column mb-3">
              <label for="email" class="col-form-label">Email Address</label>
              <input type="email" class="form-control" id="email" name="email" formControlName="email" placeholder="Email Address">
              <div *ngIf="signUpForm.get('email')?.errors && signUpForm.get('email')?.touched">
                <small class="text-danger" *ngIf="signUpForm.get('email')?.errors?.['required']">
                  Email is required.
                </small>
                <small class="text-danger" *ngIf="signUpForm.get('email')?.errors?.['email']">
                  Enter a valid email.
                </small>
              </div>
            </div>
            <div class="form-column mb-3">
              <label for="password" class="col-form-label">Password</label>
              <input type="password" class="form-control" id="password" name="password" formControlName="password" placeholder="Password">
              <div *ngIf="signUpForm.get('password')?.errors && signUpForm.get('password')?.touched">
                <small class="text-danger" *ngIf="signUpForm.get('password')?.errors?.['required']">
                  Password is required.
                </small>
                <small class="text-danger" *ngIf="signUpForm.get('password')?.errors?.['minLength']">
                  Enter a valid Password.
                </small>
                <small class="text-danger" *ngIf="signUpForm.get('password')?.errors?.['passwordStrength']">
                  Password must be at least 8 characters, contain one uppercase letter, and one symbol.
                </small>
              </div>
            </div>
            <div class="form-column mb-3">
              <label for="repeatPassword" class="col-form-label">Repeat Password</label>
              <input type="password" class="form-control" id="repeatPassword" name="repeatPassword" formControlName="repeatPassword" placeholder="Password Confirmation">
              <small class="text-danger" *ngIf="signUpForm.get('password')?.value !== signUpForm.get('repeatPassword')?.value">
                Passwords do not match.
              </small>
            </div>
            <div class="form-column mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="agree-terms" formControlName="termsAgreement" style="border: 1px solid #0095ff;box-shadow: 0 0 3px 2px #0095ff;">
              <label class="form-check-label" for="agree-terms">I have read and agree to
                <a href="https://veriez.com/terms-of-use">Terms of Use</a> and to <a href="https://veriez.com/terms-and-conditions">Terms and Conditions</a>
              </label>
              <small class="text-danger" *ngIf="!isClean">
                You must accept to proceed.
              </small>
            </div>
            <button type="submit" class="btn btn-primary w-100 mb-4" [disabled]="!signUpForm.valid || signUpForm.get('password')?.value !== signUpForm.get('repeatPassword')?.value">Sign Up</button>
            <p class="text-center mb-0"><strong>Already have an account? <a routerLink="/sign-in">Sign In</a></strong></p>
          </form>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
