<div class="content-wrapper-top">
  <h3>User Profile</h3>
  <p class="mb-4">View your user profile here</p>
  <div class="d-flex align-items-center flex-wrap flex-sm-nowrap row-gap-3">
    <div class="flex-shrink-0 me-4 pe-2 photo-upload-container">
      <img class="rounded-circle" [src]="(customerAccount && customerAccount.customerProfileInfoView && customerAccount.customerProfileInfoView.photoUrl) || 'assets/img/user-profile.jpg'" alt="User" height="115">
      <div class="overlay" (click)="fileInput.click()" >
        <button class="btn btn-primary photo-upload-btn">Upload</button>
        <input type="file" #fileInput id="fileInput" accept="image/*" style="display: none;" (change)="onFileChange($event, uploadPictureModal)">
      </div>
      <img *ngIf="verificationDetail && verificationDetail.photoVerified"
           src="assets/img/verified-icon.svg" alt="Verified" placement="top" ngbTooltip="Verified By {{verificationDetail.photoVerifiedBy}} on {{verificationDetail.photoVerifiedTimestamp | date:'medium'}}">
    </div>
    <div class="d-flex align-items-center flex-wrap column-gap-5 row-gap-3">
      <div class="flex-grow-1 me-3">
        <p class="mb-1 fw-semibold">Name
          <img *ngIf="verificationDetail && verificationDetail.nameVerified"
               src="assets/img/verified-icon.svg" alt="Verified" placement="top" ngbTooltip="Verified By {{verificationDetail.nameVerifiedBy}} on {{verificationDetail.nameVerifiedTimestamp | date:'medium'}}">
        </p>
        <h5>{{customerAccount.customerContactInfoView.firstName | titlecase}} {{customerAccount.customerContactInfoView.lastName | titlecase}}</h5>
      </div>
      <div class="flex-grow-1 me-3">
        <p class="mb-1 fw-semibold">Email
          <img *ngIf="verificationDetail && verificationDetail.emailVerified"
               placement="top" ngbTooltip="Verified By {{verificationDetail.emailVerifiedBy}} on {{verificationDetail.emailVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
        </p>
        <h5>{{customerAccount.email}}</h5>
      </div>
      <div class="flex-grow-1">
        <p class="mb-1 fw-semibold">Joined Date</p>
        <h5>{{customerAccount.createdTimestamp | date : "short"}}</h5>
      </div>
    </div>
  </div>
  <ul ngbNav #nav="ngbNav" class="nav profile-tabs-nav">
    <li ngbNavItem class="nav-item" [ngbNavItem]="1">
      <button ngbNavLink class="nav-link">Profile Info</button>
      <ng-template ngbNavContent>
        <div class="tab-pane fade show active" *ngIf="customerAccount.customerProfileInfoView">
          <div class="d-flex align-items-center justify-content-between gap-3 mb-4">
            <h6 class="mb-0 gray-color fw-medium">View and edit your profile here</h6>
            <button class="btn btn-outline-primary" (click)="modalSelect(content, 1)">Edit</button>
          </div>
          <div class="row row-gap-4">
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/calendar-icon.svg" alt="Date of Birth">
                <p class="small-txt fw-semibold mb-2 mt-3">Date of Birth
                  <img *ngIf="verificationDetail && verificationDetail.birthDateVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.birthDateVerifiedBy}} on {{verificationDetail.birthDateVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerProfileInfoView.dateOfBirth | date : "longDate"}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/height-icon.svg" alt="Height">
                <p class="small-txt fw-semibold mb-2 mt-3">Height
                  <img *ngIf="verificationDetail && verificationDetail.heightVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.heightVerifiedBy}} on {{verificationDetail.heightVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerProfileInfoView.height | convertHeight}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/weight-icon.svg" alt="Weight (lbs)">
                <p class="small-txt fw-semibold mb-2 mt-3">Weight (lbs)
                  <img *ngIf="verificationDetail && verificationDetail.weightVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.weightVerifiedBy}} on {{verificationDetail.weightVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerProfileInfoView.weight}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/gender-icon.svg" alt="Gender">
                <p class="small-txt fw-semibold mb-2 mt-3">Gender
                  <img *ngIf="verificationDetail && verificationDetail.genderVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.genderVerifiedBy}} on {{verificationDetail.genderVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerProfileInfoView.gender}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/human-race-icon.svg" alt="Race">
                <p class="small-txt fw-semibold mb-2 mt-3">Race
                  <img *ngIf="verificationDetail && verificationDetail.raceVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.raceVerifiedBy}} on {{verificationDetail.raceVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerProfileInfoView.race}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/relationship-status-icon.svg" alt="Relationship Status">
                <p class="small-txt fw-semibold mb-2 mt-3">Relationship Status
                  <img *ngIf="verificationDetail && verificationDetail.relationshipStatusVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.relationshipStatusVerifiedBy}} on {{verificationDetail.relationshipStatusVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerProfileInfoView.relationshipStatus}}</h6>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem class="nav-item" [ngbNavItem]="2">
      <button ngbNavLink class="nav-link">Contact Info</button>
      <ng-template ngbNavContent>
        <div class="tab-pane fade show active"  *ngIf="customerAccount.customerContactInfoView">
          <div class="d-flex align-items-center justify-content-between gap-3 mb-4">
            <h6 class="mb-0 gray-color fw-medium">View and edit your contact info here</h6>
            <button type="button" class="btn btn-outline-primary" (click)="modalSelect(content, 2)">Edit</button>
          </div>
          <div class="row row-gap-4">
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/email-icon.svg" alt="Email Address">
                <p class="small-txt fw-semibold mb-2 mt-3">Email Address
                  <img *ngIf="verificationDetail && verificationDetail.emailVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.emailVerifiedBy}} on {{verificationDetail.emailVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.email}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/phone-icon.svg" alt="Phone No">
                <p class="small-txt fw-semibold mb-2 mt-3">Phone No.
                  <img *ngIf="verificationDetail && verificationDetail.phoneNumberVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.phoneNumberVerifiedBy}} on {{verificationDetail.phoneNumberVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerContactInfoView.countryCode}} {{customerAccount.customerContactInfoView.phoneNumber}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/address-icon.svg" alt="Address 1">
                <p class="small-txt fw-semibold mb-2 mt-3">Address 1
                  <img *ngIf="verificationDetail && verificationDetail.addressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.addressVerifiedBy}} on {{verificationDetail.addressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerContactInfoView.address1}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3" *ngIf="customerAccount.customerContactInfoView.address2">
              <div class="card-block">
                <img src="assets/img/address-icon.svg" alt="Address 2">
                <p class="small-txt fw-semibold mb-2 mt-3">Address 2
                  <img *ngIf="verificationDetail && verificationDetail.addressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.addressVerifiedBy}} on {{verificationDetail.addressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerContactInfoView.address2}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/building-icon.svg" alt="City">
                <p class="small-txt fw-semibold mb-2 mt-3">City
                  <img *ngIf="verificationDetail && verificationDetail.addressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.addressVerifiedBy}} on {{verificationDetail.addressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerContactInfoView.city}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/map-icon.svg" alt="State">
                <p class="small-txt fw-semibold mb-2 mt-3">State
                  <img *ngIf="verificationDetail && verificationDetail.addressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.addressVerifiedBy}} on {{verificationDetail.addressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerContactInfoView.state}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/zip-code-icon.svg" alt="zip">
                <p class="small-txt fw-semibold mb-2 mt-3">Zip
                  <img *ngIf="verificationDetail && verificationDetail.addressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.addressVerifiedBy}} on {{verificationDetail.addressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerContactInfoView.zipCode}}</h6>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem class="nav-item">
      <button ngbNavLink class="nav-link" (click)="checkEmployment(content)">Employment Info</button>
      <ng-template ngbNavContent>
        <div class="tab-pane fade show active" *ngIf="customerAccount.customerEmploymentInfoView">
          <div class="d-flex align-items-center justify-content-between gap-3 mb-4">
            <h6 class="mb-0 gray-color fw-medium">View and edit your employment info here</h6>
            <button type="button" class="btn btn-outline-primary" (click)="modalSelect(content, 3)">Edit</button>
          </div>
          <div class="row row-gap-4">
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/id-card-icon.svg" alt="Employer Name">
                <p class="small-txt fw-semibold mb-2 mt-3">Employer Name
                  <img *ngIf="verificationDetail && verificationDetail.employerNameVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerNameVerifiedBy}} on {{verificationDetail.employerNameVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerEmploymentInfoView.customerEmployerName}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/phone-icon.svg" alt="Employer Phone No">
                <p class="small-txt fw-semibold mb-2 mt-3">Employer Phone No.
                  <img *ngIf="verificationDetail && verificationDetail.employerPhoneNumberVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerPhoneNumberVerifiedBy}} on {{verificationDetail.employerPhoneNumberVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerEmploymentInfoView.customerEmployerPhoneNumber}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/human-race-icon.svg" alt="Employment Position">
                <p class="small-txt fw-semibold mb-2 mt-3">Employment Position
                  <img *ngIf="verificationDetail && verificationDetail.employmentPositionVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employmentPositionVerifiedBy}} on {{verificationDetail.employmentPositionVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerEmploymentInfoView.customerEmploymentPosition}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/address-icon.svg" alt="Employer Address 1">
                <p class="small-txt fw-semibold mb-2 mt-3">Employer Address 1
                  <img *ngIf="verificationDetail && verificationDetail.employerAddressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerAddressVerifiedBy}} on {{verificationDetail.employerAddressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerEmploymentInfoView.customerEmployerAddress1}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3" *ngIf="customerAccount.customerEmploymentInfoView.customerEmployerAddress2">
              <div class="card-block">
                <img src="assets/img/address-icon.svg" alt="Employer Address 2">
                <p class="small-txt fw-semibold mb-2 mt-3">Employer Address 2
                  <img *ngIf="verificationDetail && verificationDetail.employerAddressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerAddressVerifiedBy}} on {{verificationDetail.employerAddressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerEmploymentInfoView.customerEmployerAddress2}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/building-icon.svg" alt="Employer City">
                <p class="small-txt fw-semibold mb-2 mt-3">Employer City
                  <img *ngIf="verificationDetail && verificationDetail.employerAddressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerAddressVerifiedBy}} on {{verificationDetail.employerAddressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerEmploymentInfoView.customerEmployerCity}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/map-icon.svg" alt="Employer State">
                <p class="small-txt fw-semibold mb-2 mt-3">Employer State
                  <img *ngIf="verificationDetail && verificationDetail.employerAddressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerAddressVerifiedBy}} on {{verificationDetail.employerAddressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerEmploymentInfoView.customerEmployerState}}</h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/zip-code-icon.svg" alt="Employer Zip">
                <p class="small-txt fw-semibold mb-2 mt-3">Employer Zip
                  <img *ngIf="verificationDetail && verificationDetail.employerAddressVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerAddressVerifiedBy}} on {{verificationDetail.employerAddressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </p>
                <h6 class="mb-0">{{customerAccount.customerEmploymentInfoView.customerEmployerZipCode}}</h6>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem class="nav-item">
      <button ngbNavLink class="nav-link" (click)="checkEducation(addContent)">Education Info</button>
      <ng-template ngbNavContent>
        <div class="tab-pane fade show active" *ngIf="customerAccount.customerEducationInfoView">
              <div class="d-flex align-items-center justify-content-between gap-3 mb-4">
                <h6 class="mb-0 gray-color fw-medium">View and edit your education info here</h6>
                <button type="button" class="btn btn-outline-primary" (click)="modalSelect(addContent, 4)">Edit</button>
              </div>
              <div>
                <div class="row row-gap-4">
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/schooling-icon.svg" alt="School">
                      <p class="small-txt fw-semibold mb-2 mt-3">School</p>
                      <h6 class="mb-0">{{customerAccount.customerEducationInfoView.institutionName}}</h6>
                      <!--<img *ngIf="verificationDetail && verificationDetail.employerAddressVerified"
                           placement="top" ngbTooltip="Verified By {{verificationDetail.employerAddressVerifiedBy}} on {{verificationDetail.employerAddressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">-->
                    </div>
                  </div>
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/degree-cap-icon.svg" alt="Degree">
                      <p class="small-txt fw-semibold mb-2 mt-3">Degree</p>
                      <h6 class="mb-0">{{customerAccount.customerEducationInfoView.degree}}</h6>
                      <!--<img *ngIf="verificationDetail && verificationDetail.employerAddressVerified"
                           placement="top" ngbTooltip="Verified By {{verificationDetail.employerAddressVerifiedBy}} on {{verificationDetail.employerAddressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">-->
                    </div>
                  </div>
                  <div class="col-sm-6 col-xxl-3">
                    <div class="card-block">
                      <img src="assets/img/weight-icon.svg" alt="Grade">
                      <p class="small-txt fw-semibold mb-2 mt-3">Graduated</p>
                      <h6 class="mb-0">{{customerAccount.customerEducationInfoView.graduated | booleanToYesNo}}</h6>
                      <!--<img *ngIf="verificationDetail && verificationDetail.employerAddressVerified"
                           placement="top" ngbTooltip="Verified By {{verificationDetail.employerAddressVerifiedBy}} on {{verificationDetail.employerAddressVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </ng-template>
    </li>
    <!--<li ngbNavItem class="nav-item">
      <button ngbNavLink class="nav-link">Social Media Info (Coming Soon)</button>
      <ng-template ngbNavContent *ngIf="false">
        <div class="tab-pane fade show active">
          <div class="d-flex align-items-center justify-content-between gap-3 mb-4">
            <h6 class="mb-0 gray-color fw-medium">View and edit your social media info here</h6>
            <button class="btn btn-outline-primary" (click)="modalSelect(content, 4)">Edit</button>
          </div>
          <div class="row row-gap-4">
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="150px" height="150px" viewBox="0 0 150 150" xml:space="preserve" class="social-media-icon"><g id="apple"><path class="st0" d="M75.2,15.2c47,0,59.8,12.4,59.8,60.5c0,47-13.1,59.2-60.2,59.2c-48.1,0-59.8-12.5-59.8-59.5 C15,27.2,27.1,15.2,75.2,15.2z" style="clip-rule: evenodd; fill: rgb(112, 113, 113); fill-rule: evenodd;"></path><path class="st1" d="M90,74.2c-0.1-6.8,5.5-10,5.8-10.2c-3.1-4.6-8.1-5.2-9.8-5.3c-4.2-0.4-8.1,2.5-10.3,2.5 c-2.1,0-5.4-2.4-8.8-2.3c-4.5,0.1-8.7,2.6-11.1,6.7c-4.7,8.2-1.2,20.3,3.4,27c2.3,3.3,4.9,6.9,8.5,6.8c3.4-0.1,4.7-2.2,8.8-2.2 c4.1,0,5.3,2.2,8.8,2.1c3.7-0.1,6-3.3,8.2-6.6c2.6-3.8,3.6-7.4,3.7-7.6C97.1,85.1,90.1,82.4,90,74.2z M83.3,54.3 c1.9-2.3,3.1-5.4,2.8-8.6c-2.7,0.1-6,1.8-7.9,4.1c-1.7,2-3.3,5.2-2.8,8.3C78.3,58.4,81.4,56.6,83.3,54.3z" style="fill: rgb(255, 255, 255);"></path></g></svg>
                <p class="small-txt fw-semibold mb-2 mt-3"></p>
                <h6 class="mb-0">{{customerAccount.customerProfileInfoView.relationshipStatus}}
                  <img *ngIf="verificationDetail && verificationDetail.relationshipStatusVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.relationshipStatusVerifiedBy}} on {{verificationDetail.relationshipStatusVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="150px" height="150px" viewBox="0 0 150 150" xml:space="preserve" class="social-media-icon"><g id="facebook"><path class="st0" d="M75.2,15.2c47,0,59.8,12.4,59.8,60.5c0,47-13.1,59.2-60.2,59.2c-48.1,0-59.8-12.5-59.8-59.5 C15,27.2,27.1,15.2,75.2,15.2z" style="clip-rule: evenodd; fill: rgb(74, 110, 180); fill-rule: evenodd;"></path><path class="st1" d="M90,66.3h-9.5v-6.2c0-2.3,1.5-2.9,2.6-2.9c1.1,0,6.7,0,6.7,0V47l-9.2,0c-10.2,0-12.5,7.6-12.5,12.5v6.8h-5.9 v10.6h5.9c0,13.5,0,29.9,0,29.9h12.4c0,0,0-16.5,0-29.9h8.4L90,66.3z" style="fill: rgb(255, 255, 255);"></path></g></svg>
                <p class="small-txt fw-semibold mb-2 mt-3"></p>
                <h6 class="mb-0">{{customerAccount.customerProfileInfoView.relationshipStatus}}
                  <img *ngIf="verificationDetail && verificationDetail.relationshipStatusVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.relationshipStatusVerifiedBy}} on {{verificationDetail.relationshipStatusVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="150px" height="150px" viewBox="0 0 150 150" xml:space="preserve" class="social-media-icon"><g id="google"><path class="st0" d="M75.2,15.2c47,0,59.8,12.4,59.8,60.5c0,47-13.1,59.2-60.2,59.2c-48.1,0-59.8-12.5-59.8-59.5 C15,27.2,27.1,15.2,75.2,15.2z" style="clip-rule: evenodd; fill: rgb(255, 255, 255); fill-rule: evenodd;"></path><g><path class="st1" d="M103.4,75.6c0-2.3-0.2-4-0.6-5.8H75.6v10.5h16c-0.3,2.6-2.1,6.6-5.9,9.2l-0.1,0.4l8.6,6.5l0.6,0.1 C100.3,91.6,103.4,84.3,103.4,75.6" style="fill: rgb(66, 133, 244);"></path><path class="st2" d="M75.6,103.4c7.8,0,14.4-2.5,19.2-6.9l-9.2-6.9c-2.5,1.7-5.7,2.8-10.1,2.8c-7.7,0-14.2-5-16.5-11.8l-0.3,0 l-9,6.8l-0.1,0.3C54.4,97,64.3,103.4,75.6,103.4" style="fill: rgb(52, 168, 83);"></path><path class="st3" d="M59.1,80.6c-0.6-1.8-1-3.7-1-5.6c0-2,0.4-3.9,0.9-5.6l0-0.4L50,62.1l-0.3,0.1c-2,3.9-3.1,8.2-3.1,12.8 c0,4.6,1.1,8.9,3.1,12.8L59.1,80.6" style="fill: rgb(251, 188, 5);"></path><path class="st4" d="M75.6,57.6c5.5,0,9.1,2.3,11.2,4.2L95,54c-5-4.6-11.6-7.4-19.4-7.4c-11.4,0-21.2,6.4-25.9,15.7l9.4,7.1 C61.4,62.6,67.9,57.6,75.6,57.6" style="fill: rgb(235, 67, 53);"></path></g></g></svg>
                <p class="small-txt fw-semibold mb-2 mt-3"></p>
                <h6 class="mb-0">{{customerAccount.customerProfileInfoView.relationshipStatus}}
                  <img *ngIf="verificationDetail && verificationDetail.relationshipStatusVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.relationshipStatusVerifiedBy}} on {{verificationDetail.relationshipStatusVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">
                </h6>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>-->
    <li ngbNavItem class="nav-item">
      <button ngbNavLink class="nav-link">Background Check Info</button>
      <ng-template ngbNavContent>
        <div class="tab-pane fade show active">
          <div class="d-flex align-items-center justify-content-between gap-3 mb-4">
            <h6 class="mb-0 gray-color fw-medium">View and edit your Background Check Info here</h6>
            <button type="button" class="btn btn-outline-primary" (click)="backgroundCheckModal(backgroundContent)">Order Background Check</button>
          </div>
          <p>Background Check Status:
            <span *ngIf="customerAccount.customerBackgroundInfoView && customerAccount.customerCheckrInfoView && !customerAccount.customerBackgroundInfoView.sexOffenderRegistry && customerAccount.customerCheckrInfoView.checkrInvitationUrl">Running</span>
            <span *ngIf="customerAccount.customerBackgroundInfoView && customerAccount.customerCheckrInfoView && customerAccount.customerBackgroundInfoView.sexOffenderRegistry && customerAccount.customerCheckrInfoView.checkrInvitationUrl">Completed!</span>
            <span *ngIf="!customerAccount.customerBackgroundInfoView || !customerAccount.customerCheckrInfoView || !customerAccount.customerCheckrInfoView.checkrInvitationUrl">Not Started</span>
          </p>
          <div class="row row-gap-4">
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/id-card-icon.svg" alt="SSN Trace">
                <p class="small-txt fw-semibold mb-2 mt-3">SSN Trace</p>
                <h6 class="mb-0" *ngIf="customerAccount.customerBackgroundInfoView">{{customerAccount.customerBackgroundInfoView.ssnTrace}}
                  <!--<img *ngIf="verificationDetail && verificationDetail.employerNameVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerNameVerifiedBy}} on {{verificationDetail.employerNameVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">-->
                </h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/id-card-icon.svg" alt="Sex Offender Registry">
                <p class="small-txt fw-semibold mb-2 mt-3">Sex Offender Registry</p>
                <h6 class="mb-0" *ngIf="customerAccount.customerBackgroundInfoView">{{customerAccount.customerBackgroundInfoView.sexOffenderRegistry}}
                  <!--<img *ngIf="verificationDetail && verificationDetail.employerNameVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerNameVerifiedBy}} on {{verificationDetail.employerNameVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">-->
                </h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/id-card-icon.svg" alt="Global Watchlist Records">
                <p class="small-txt fw-semibold mb-2 mt-3">Global Watchlist Records</p>
                <h6 class="mb-0" *ngIf="customerAccount.customerBackgroundInfoView">{{customerAccount.customerBackgroundInfoView.globalWatchlistRecord}}
                  <!--<img *ngIf="verificationDetail && verificationDetail.employerNameVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerNameVerifiedBy}} on {{verificationDetail.employerNameVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">-->
                </h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/id-card-icon.svg" alt="National Criminal Records">
                <p class="small-txt fw-semibold mb-2 mt-3">National Criminal Records</p>
                <h6 class="mb-0" *ngIf="customerAccount.customerBackgroundInfoView">{{customerAccount.customerBackgroundInfoView.nationalWatchlistRecord}}
                  <!--<img *ngIf="verificationDetail && verificationDetail.employerNameVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerNameVerifiedBy}} on {{verificationDetail.employerNameVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">-->
                </h6>
              </div>
            </div>
            <div class="col-sm-6 col-xxl-3">
              <div class="card-block">
                <img src="assets/img/id-card-icon.svg" alt="County Criminal Search">
                <p class="small-txt fw-semibold mb-2 mt-3">County Criminal Search</p>
                <h6 class="mb-0" *ngIf="customerAccount.customerBackgroundInfoView">{{customerAccount.customerBackgroundInfoView.countyCriminalSearch}}
                  <!--<img *ngIf="verificationDetail && verificationDetail.employerNameVerified"
                       placement="top" ngbTooltip="Verified By {{verificationDetail.employerNameVerifiedBy}} on {{verificationDetail.employerNameVerifiedTimestamp | date:'medium'}}" src="assets/img/verified-icon.svg" alt="Verified">-->
                </h6>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
</div>

<div class="content-wrapper-middle">
  <div class="tab-content profile-tab-content">
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>

<ng-template #backgroundContent let-modal>
  <div *ngIf="!isBackgroundLinkGenerated">
    <div class="modal-header px-4">
      <h4 class="modal-title">Choose an Option</h4>
    </div>
    <div class="modal-body form-body px-4">
      <div class="row">
        <div class="col-md-4" *ngFor="let checkrPackage of checkrPackages">
          <div class="card text-center">
            <div class="card-header text-white bg-primary">
              {{checkrPackage.name}}
            </div>
            <div class="card-body">
              <h2 class="card-title">${{checkrPackage.price}}</h2>
              <ul class="list-group list-group-flush" *ngFor="let screening of checkrPackage.screenings">
                <li class="list-group-item">{{screening.name}}</li>
              </ul>
              <button [disabled]="!customerAccount.customerContactInfoView" (click)="orderBackgroundCheck(checkrPackage.name)" class="btn btn-primary mt-3">Get started</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between border-0 px-4 pb-4 pt-0">
      <p>Service provided by Checkr. By selecting an option, you agree to Checkr's Privacy Policy</p>
      <button type="button" class="btn btn-outline-danger" (click)="modalService.dismissAll()">Cancel</button>
    </div>
  </div>
  <div *ngIf="isBackgroundLinkGenerated">
    <div class="modal-header px-4">
      <h4 class="modal-title">Your Background Check Payment Link</h4>
    </div>
    <div class="modal-body form-body px-4">
      <p>Scan this code with your phone</p>
      <img [src]="qrCodeUrl" alt="QR Code" />
      <a class="btn btn-link" href="{{backgroundLinkGenerated}}">Or click here</a>
    </div>
    <div class="modal-footer justify-content-between border-0 px-4 pb-4 pt-0">
      <p>Service provided by Checkr. By continuing, you agree to Checkr's Privacy Policy</p>
      <button type="button" class="btn btn-outline-danger" (click)="modalService.dismissAll()">Dismiss</button>
    </div>
  </div>
</ng-template>

<ng-template #content let-modal>
  <ng-container *ngIf="currentStep === 1">
    <div class="modal-header px-4">
      <h4 class="modal-title">Edit Profile info</h4>
    </div>
    <form [formGroup]="profileInfoForm">
      <div class="modal-body form-body px-4">
        <div class="form-column">
          <label for="dateOfBirth" class="col-form-label">Date of Birth</label>
          <input type="date" class="form-control" id="dateOfBirth" name="dateOfBirth" formControlName="dateOfBirth">
        </div>
        <div class="form-column">
          <label class="col-form-label">Height</label>
          <div class="input-group">
            <input type="text" class="form-control" id="heightFeet" name="heightFeet" formControlName="heightFeet" placeholder="Feet">
            <input type="text" class="form-control" id="heightInches" name="heightInches" formControlName="heightInches" placeholder="Inches">
          </div>
        </div>
        <div class="form-column">
          <label for="weight" class="col-form-label">Weight (Lbs)</label>
          <input type="text" class="form-control" id="weight" name="weight" formControlName="weight">
        </div>
        <div class="form-column">
          <label for="gender" class="col-form-label">Gender</label>
          <select id="gender" name="gender" class="form-control" formControlName="gender" #genderValue>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Non-Binary">Other</option>
          </select>
        </div>
        <div class="form-column" *ngIf="genderValue && genderValue.value && genderValue.value === 'Non-Binary'">
          <input type="text" class="form-control" formControlName="gender">
        </div>
        <div class="row ">
          <div class="col-sm-6">
            <div class="form-column">
              <label for="race" class="col-form-label">Race</label>
              <select id="race" name="race" class="form-control" formControlName="race">
                <option value="Caucasian">White or Caucasian</option>
                <option value="HispanicLatino">Hispanic or Latino</option>
                <option value="AfricanAmerican">Black or African American</option>
                <option value="AmericanIndian">American Indian</option>
                <option value="NativeHawaiian">Native Hawaiian</option>
                <option value="AlaskanNative">Alaskan Native</option>
                <option value="Asian">Asian</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-column">
              <label for="relationshipStatus" class="col-form-label">Relationship Status:</label>
              <select id="relationshipStatus" name="relationshipStatus" class="form-control" formControlName="relationshipStatus">
                <option value="Single">Single</option>
                <option value="InRelationship">In a relationship</option>
                <option value="Engaged">Engaged</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
                <option value="Widowed">Widowed</option>
                <option value="Separated">Separated</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="currentStep === 2">
    <div class="modal-header px-4">
      <h4 class="modal-title">Edit Contact info</h4>
    </div>
    <form [formGroup]="contactInfoForm">
      <div class="modal-body form-body px-4">
        <div class="form-column">
          <label for="phoneNumber" class="col-form-label">Phone Number</label>
          <div class="input-group">
            <label for="phoneNumber" class="form-control">+1</label>
            <input type="tel" appPhoneNumberFormat class="form-control" id="phoneNumber" formControlName="phoneNumber">
          </div>
        </div>
        <div class="form-column">
          <label for="addressLine1" class="form-label">Address Line 1</label>
          <input type="text" class="form-control" id="addressLine1" formControlName="addressLine1" placeholder="1234 Main St">
        </div>
        <div class="form-column">
          <label for="addressLine2" class="form-label">Address Line 2</label>
          <input type="text" class="form-control" id="addressLine2" formControlName="addressLine2" placeholder="Apartment or suite">
        </div>
        <div class="form-column">
          <label for="city" class="form-label">City</label>
          <input type="text" class="form-control" id="city" formControlName="city" placeholder="City">
        </div>
        <div class="form-column">
          <label for="state" class="form-label">State</label>
          <select class="form-control" id="state" formControlName="state">
            <option *ngFor="let state of states" [value]="state.abbreviation">
              {{state.name}}
            </option>
          </select>
        </div>
        <div class="form-column">
          <label for="zip" class="form-label">Zip</label>
          <input type="text" class="form-control" id="zip" formControlName="zip" placeholder="12345">
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="currentStep === 3">
    <div class="modal-header px-4">
      <h4 class="modal-title">Edit Employment info</h4>
    </div>
    <form [formGroup]="employmentForm">
      <div class="modal-body form-body px-4">
        <div class="form-column">
          <label for="employerName" class="form-label">Employer Name</label>
          <input type="text" class="form-control" id="employerName" formControlName="employerName" placeholder="Company Inc.">
        </div>
        <div class="form-column">
          <label for="position" class="form-label">Employment Position</label>
          <input type="text" class="form-control" id="position" formControlName="position" placeholder="Software Developer">
        </div>
        <div class="form-column">
          <label for="companyPhoneNumber" class="form-label">Employer Phone Number</label>
          <input type="tel" appPhoneNumberFormat class="form-control" id="companyPhoneNumber" formControlName="companyPhoneNumber" placeholder="123-456-7890">
        </div>
        <div class="form-column">
          <label for="employerAddress1" class="form-label">Employer Address Line 1</label>
          <input type="text" class="form-control" id="employerAddress1" formControlName="employerAddress1" placeholder="1234 Main St">
        </div>
        <div class="form-column">
          <label for="employerAddress2" class="form-label">Employer Address Line 2</label>
          <input type="text" class="form-control" id="employerAddress2" formControlName="employerAddress2" placeholder="Apartment or suite">
        </div>
        <div class="form-column">
          <label for="employerCity" class="form-label">Employer City</label>
          <input type="text" class="form-control" id="employerCity" formControlName="employerCity">
        </div>
        <div class="form-column">
          <div class="col-md-6 mb-3">
            <label for="employerState" class="form-label">Employer State</label>
            <select class="form-select" id="employerState" formControlName="employerState">
              <option *ngFor="let state of states" [value]="state.abbreviation">
                {{state.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-column">
          <div class="col-md-6 mb-3">
            <label for="employerZip" class="form-label">Employer Zip</label>
            <input type="text" class="form-control" id="employerZip" formControlName="employerZip">
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  <div class="modal-footer justify-content-between border-0 px-4 pb-4 pt-0">
    <button type="button" class="btn btn-primary" (click)="saveUpdateInfo()" data-bs-dismiss="modal" [disabled]="!profileInfoForm.valid
        && !contactInfoForm.valid && !employmentForm.valid && !identificationForm.valid">Save</button>
    <button type="button" class="btn btn-outline-danger" (click)="modalService.dismissAll()">Cancel</button>
  </div>
</ng-template>

<ng-template #uploadPictureModal let-modal>
  <div class="modal-header px-4">
    <h4 class="modal-title">Crop your picture</h4>
  </div>
  <div class="modal-body form-body px-4">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()">
    </image-cropper>
  </div>
  <div class="modal-footer justify-content-between border-0 px-4 pb-4 pt-0">
    <button type="button" class="btn btn-primary" (click)="uploadCroppedImage()">Save</button>
    <button type="button" class="btn btn-outline-danger" (click)="modalService.dismissAll()">Cancel</button>
  </div>
</ng-template>

<ng-template #addContent let-modal>
  <ng-container>
    <div class="modal-header px-4">
      <h4 class="modal-title">Add Education Info</h4>
    </div>
    <form [formGroup]="educationForm">
      <div class="modal-body form-body px-4">
        <div class="form-column">
          <label for="employerName" class="form-label">Institution Name</label>
          <input type="text" class="form-control" id="institutionName" formControlName="institutionName" placeholder="Florida International University">
        </div>
        <div class="form-column">
          <div class="col-md-6 mb-3">
            <label for="institutionType" class="form-label">Institution Type</label>
            <select class="form-select" id="institutionType" formControlName="institutionType">
              <option *ngFor="let type of institutionTypes">
                {{type.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-column">
          <label for="position" class="form-label">Degree</label>
          <input type="text" class="form-control" id="degree" formControlName="degree" placeholder="Diploma">
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="graduated" formControlName="graduated">
          <label for="graduated" class="form-label">Graduated</label>
        </div>
        <div class="form-column">
          <label for="started" class="form-label">Started Date</label>
          <input type="date" class="form-control" id="started" formControlName="started">
        </div>
        <div class="form-column">
          <label for="ended" class="form-label">End Date</label>
          <input type="date" class="form-control" id="ended" formControlName="ended">
        </div>
      </div>
    </form>
  </ng-container>
  <div class="modal-footer justify-content-between border-0 px-4 pb-4 pt-0">
    <button type="button" class="btn btn-primary" (click)="saveNewEducation()" [disabled]="!educationForm.valid">Save</button>
    <button type="button" class="btn btn-outline-danger" (click)="modalService.dismissAll()">Cancel</button>
  </div>
</ng-template>
