<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6 col-lg-4 confirmation-container">
      <h2 class="text-center mb-4">Payment Confirmed!</h2>
        <!--<div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input type="email" class="form-control" id="email" name="email" formControlName="email" aria-describedby="emailHelp">
          <div *ngIf="signInForm.get('email')?.errors && signInForm.get('email')?.touched">
            <small class="text-danger" *ngIf="signInForm.get('email')?.errors?.['required']">
              Email is required.
            </small>
            <small class="text-danger" *ngIf="signInForm.get('email')?.errors?.['email']">
              Enter a valid email.
            </small>
          </div>
          &lt;!&ndash; Email verification error &ndash;&gt;
          <small class="text-danger" *ngIf="emailNotVerified">
            Your email has not been verified. Please check your inbox.
          </small>
        </div>-->
        <!--<div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input type="password" class="form-control" id="password" name="password" formControlName="password">
          <div *ngIf="signInForm.get('password')?.errors && signInForm.get('password')?.touched">
            <small class="text-danger" *ngIf="signInForm.get('password')?.errors?.['required']">
              Password is required.
            </small>
          </div>
        </div>-->
      <div class="mt-3">
        <p class="text-center">Let's go to your dashboard! <a routerLink="/dashboard">Click Here</a></p>
      </div>
    </div>
  </div>
</div>
