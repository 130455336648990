import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {OldPwdValidators} from "./old-pwd.validators";
import {AuthService} from "../../services/auth.service";
import {passwordValidator} from "../../functions/passwordValidator";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit{
  form1 = this.formBuilder.group({
    'oldPwd': ['',Validators.required],
    'newPwd': ['',Validators.required],
    'confirmPwd': ['',[Validators.required, passwordValidator()]]
  });

  constructor(private formBuilder: FormBuilder,public authService:AuthService){
  }

  ngOnInit(): void {
    if(localStorage.getItem('sb|sidebar-toggle') && localStorage.getItem('sb|sidebar-toggle')==='true'){
      document.body.classList.toggle('sb-sidenav-toggled');
    }
  }

  get oldPwd(){
    return this.form1.get('oldPwd');
  }

  get newPwd(){
    return this.form1.get('newPwd');
  }

  get confirmPwd(){
    return this.form1.get('confirmPwd');
  }

  submitChangePassword() {
    this.authService.changePassword(this.form1.value.newPwd!);
  }
}
