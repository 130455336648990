<div class="content-wrapper-top">
  <h3 class="mb-0">Analytics</h3>
</div>
<div class="content-wrapper-middle">
  <div class="table-responsive">
    <table class="table table-bordered custom-table">
      <thead>
      <tr class="table-dark">
        <th scope="col">Date Created</th>
        <th scope="col">URL</th>
        <th scope="col">Pin Code</th>
        <th scope="col">Status</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let sharedLink of sharedLinks">
        <td>{{sharedLink.createdTimestamp | date : 'short'}}</td>
        <td *ngIf="isProd"><em id="p1">https://app.veriez.com/share/{{sharedLink.linkUrl}}</em> <button type="button" class="btn btn-link" ngxClipboard cbContent="https://app.veriez.com/share/{{sharedLink.linkUrl}}"><img src="assets/img/copy-icon.svg" alt="Copy"></button></td>
        <td *ngIf="!isProd"><em id="p1">https://app-dev.veriez.com/share/{{sharedLink.linkUrl}}</em> <button type="button" class="btn btn-link" ngxClipboard cbContent="https://app-dev.veriez.com/share/{{sharedLink.linkUrl}}"><img src="assets/img/copy-icon.svg" alt="Copy"></button></td>
        <td>{{sharedLink.pin}}</td>
        <td *ngIf="sharedLink.active"><span class="table-link link-alert">Unopened</span></td>
        <td *ngIf="!sharedLink.active"><span class="table-link">Opened</span></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
