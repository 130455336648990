import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WsService} from "../../services/ws.service";
import {CustomerAccount} from "../../models/CustomerAccount";
import {AuthService} from "../../services/auth.service";
import {LoadingService} from "../../services/loading.service";
import {CustomerProfileInfo} from "../../models/CustomerProfileInfo";
import {CustomerContactInfo} from "../../models/CustomerContactInfo";
import {CustomerEmploymentInfo} from "../../models/CustomerEmploymentInfo";
import {CustomerIdentityInfo} from "../../models/CustomerIdentityInfo";
import {FormBuilder, Validators} from "@angular/forms";
import {CustomerVerificationInfo} from "../../models/CustomerVerificationInfo";
import {from} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertService} from "../../services/alert.service";
import {CustomerVerificationDetail} from "../../models/CustomerVerificationDetail";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {QrCodeService} from "../../services/qr-code.service";
import {Router} from "@angular/router";
import {CheckrPackage} from "../../models/CheckrPackage";

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css']
})
export class CustomerDetailComponent implements OnInit{
  @Input() customerAccount!: CustomerAccount
  customerProfile!: CustomerProfileInfo
  customerContact!: CustomerContactInfo
  customerEmployment!: CustomerEmploymentInfo
  currentStep: number = 1;
  isSubmitted: boolean = false;
  states: any = [];
  countryCodes: any = [];
  institutionTypes: any = [];
  imageSrc: string | ArrayBuffer | null = '';
  maxFileSize = 5 * 1024 * 1024; // 5 MB
  @Input() verificationDetail!: CustomerVerificationDetail;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isBackgroundLinkGenerated: boolean = false;
  backgroundLinkGenerated: string = '';
  qrCodeUrl: string | null = null;
  checkrPackages: CheckrPackage[] = [];

  profileInfoForm = this.formBuilder.group({
    dateOfBirth: ['', Validators.required],
    gender: '',
    heightFeet:['', Validators.required],
    heightInches:['', Validators.required],
    weight: ['', Validators.required],
    race: '',
    relationshipStatus: ''
  });

  contactInfoForm = this.formBuilder.group({
    addressLine1: ['', Validators.required],
    addressLine2: [''],
    countryCode: ['+1', Validators.required],
    phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]], // Example phone number validation pattern
    city: ['', Validators.required],
    state: ['', Validators.required],
    zip: ['', [Validators.required, Validators.minLength(5)]]
  });

  employmentForm = this.formBuilder.group({
    employerName: ['', Validators.required],
    companyPhoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]], // Example phone number validation pattern
    employerAddress1: ['', Validators.required],
    employerAddress2: ['',],
    employerCity: ['', Validators.required],
    employerState: ['', Validators.required],
    employerZip: ['', [Validators.required], Validators.minLength(5)],
    position: ['', Validators.required]
  });

  educationForm = this.formBuilder.group({
    institutionName: ['', Validators.required],
    institutionType: ['', Validators.required],
    degree: ['', Validators.required],
    graduated: [false],
    started: ['', Validators.required],
    ended: ['']
  });

  identificationForm = this.formBuilder.group({
    idType: ['', Validators.required],
    idNumber: ['', Validators.required]
  });

  constructor(private customerService: WsService, private authService:AuthService,
              private loadingService: LoadingService, private formBuilder: FormBuilder,
              public modalService: NgbModal, private alertService: AlertService, private qrCodeService: QrCodeService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.customerService.getStates().subscribe(states=>{
      this.states = states;
    });
    this.customerService.getCountryCodes().subscribe(countryCodes =>{
      this.countryCodes = countryCodes;
    });
    this.customerService.getInstitutionTypes().subscribe(types =>{
      this.institutionTypes = types;
    });
    this.customerService.getCheckrPackages().subscribe(a=>{
      if(a){
        this.checkrPackages = a;
      }
    });
    if(this.customerAccount.customerSubscriptionView && this.customerAccount.customerSubscriptionView.backgroundCheckPayLink){
      this.backgroundLinkGenerated = this.customerAccount.customerSubscriptionView.backgroundCheckPayLink;
      this.qrCodeService.generateQRCode(this.customerAccount.customerSubscriptionView.backgroundCheckPayLink)
        .then(a=> this.qrCodeUrl = a);
      this.isBackgroundLinkGenerated = true;
    }
    if(localStorage.getItem('sb|sidebar-toggle') && localStorage.getItem('sb|sidebar-toggle')==='true'){
      document.body.classList.toggle('sb-sidenav-toggled');
    }
  }

  saveUpdateInfo() {
    this.loadingService.show();
    if(this.currentStep === 1){
      this.customerService.updateCustomerProfile(this.customerAccount!.customerAccountUuid, this.profileInfoForm.getRawValue()).subscribe(()=>{
        this.alertService.addAlert('success','Successfully updated!');
        this.getCustomerProfile(this.customerAccount!.customerAccountUuid);
        this.loadingService.hide();
      });
    } else if(this.currentStep === 2){
      this.customerService.updateCustomerContact(this.customerAccount!.customerAccountUuid, this.contactInfoForm.getRawValue()).subscribe(()=>{
        this.alertService.addAlert('success','Successfully updated!');
        this.getCustomerContact(this.customerAccount!.customerAccountUuid);
        this.loadingService.hide();
      });
    } else if(this.currentStep === 3){
      if(!this.customerAccount.customerEmploymentInfoView){
        this.customerService.addCustomerEmployment(this.customerAccount!.customerAccountUuid, this.employmentForm.getRawValue()).subscribe(()=>{
          this.alertService.addAlert('success','Successfully updated!');
          this.getCustomerEmployment(this.customerAccount!.customerAccountUuid);
          this.loadingService.hide();
          this.modalService.dismissAll();
        });
      }else{
        this.customerService.updateCustomerEmployment(this.customerAccount!.customerAccountUuid, this.employmentForm.getRawValue()).subscribe(()=>{
          this.alertService.addAlert('success','Successfully updated!');
          this.getCustomerEmployment(this.customerAccount!.customerAccountUuid);
          this.loadingService.hide();
        });
      }
    }
    this.modalService.dismissAll();
  }

  saveNewEducation(){
    this.loadingService.show();
    if(this.customerAccount.customerEducationInfoView){
      // update
      this.customerService.updateCustomerEducation(this.customerAccount!.customerAccountUuid, this.educationForm.getRawValue()).subscribe(educationInfo=>{
        this.alertService.addAlert('success','Successfully updated!');
        if(educationInfo){
          this.customerAccount.customerEducationInfoView = educationInfo;
        }
        this.loadingService.hide();
      });
    }else{
      // add
      this.customerService.addCustomerEducation(this.customerAccount!.customerAccountUuid, this.educationForm.getRawValue()).subscribe(educationInfo=>{
        this.alertService.addAlert('success','Successfully added!');
        if(educationInfo){
          this.customerAccount.customerEducationInfoView = educationInfo;
        }
        this.loadingService.hide();
      });
    }
    this.modalService.dismissAll();
  }

  backgroundCheckModal(backgroundContent: any){
    this.modalService.open(backgroundContent, {ariaLabelledBy: 'staticBackdropLabel', centered: true, keyboard: false, backdrop: "static", windowClass: "customModalClass"});
  }

  getHeightFromInches(value: string, type: string): string {
    if(value && value!=='') {
      const feet = Math.floor(parseInt(value) / 12);
      const inches = parseInt(value) % 12;
      if(type === 'feet'){
        return feet.toString();
      }
      return inches.toString();
    }
    return '';
  }

  modalSelect(content: any, stepNumber : number){
    this.modalService.open(content, {ariaLabelledBy: 'staticBackdropLabel', centered: true, keyboard: false, backdrop: "static"});
    this.currentStep = stepNumber;
    if(stepNumber === 1){
      this.profileInfoForm = this.formBuilder.group({
        dateOfBirth: [this.customerAccount.customerProfileInfoView.dateOfBirth, Validators.required],
        gender: this.customerAccount.customerProfileInfoView.gender,
        heightFeet:[this.getHeightFromInches(this.customerAccount.customerProfileInfoView.height, 'feet'), Validators.required],
        heightInches:[this.getHeightFromInches(this.customerAccount.customerProfileInfoView.height, 'inches'), Validators.required],
        weight: [this.customerAccount.customerProfileInfoView.weight, Validators.required],
        race: this.customerAccount.customerProfileInfoView.race,
        relationshipStatus: this.customerAccount.customerProfileInfoView.relationshipStatus
      });
      if(this.customerAccount.customerPlaidInfoView && this.customerAccount.customerPlaidInfoView.submittedTimestamp){
        // Cannot change birthdate after submitting for verification
        this.profileInfoForm.get('dateOfBirth')!.disable();
      }
    } else if (stepNumber === 2){
      this.contactInfoForm = this.formBuilder.group({
        addressLine1: [this.customerAccount.customerContactInfoView.address1, Validators.required],
        addressLine2: [this.customerAccount.customerContactInfoView.address2],
        countryCode: [this.customerAccount.customerContactInfoView.countryCode, Validators.required],
        phoneNumber: [this.customerAccount.customerContactInfoView.phoneNumber, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]], // Example phone number validation pattern
        city: [this.customerAccount.customerContactInfoView.city, Validators.required],
        state: [this.customerAccount.customerContactInfoView.state, Validators.required],
        zip: [this.customerAccount.customerContactInfoView.zipCode, [Validators.required, Validators.minLength(5)]]
      });
    } else if (stepNumber === 3){
      if(!this.customerAccount.customerEmploymentInfoView){
        this.employmentForm = this.formBuilder.group({
          employerName: ['', Validators.required],
          companyPhoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]], // Example phone number validation pattern
          employerAddress1: ['', Validators.required],
          employerAddress2: [''],
          employerCity: ['', Validators.required],
          employerState: ['', Validators.required],
          employerZip: ['', [Validators.required, Validators.minLength(5)]],
          position: ['', Validators.required]
        });
      }else{
        this.employmentForm = this.formBuilder.group({
          employerName: [this.customerAccount.customerEmploymentInfoView.customerEmployerName, Validators.required],
          companyPhoneNumber: [this.customerAccount.customerEmploymentInfoView.customerEmployerPhoneNumber, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]], // Example phone number validation pattern
          employerAddress1: [this.customerAccount.customerEmploymentInfoView.customerEmployerAddress1, Validators.required],
          employerAddress2: [this.customerAccount.customerEmploymentInfoView.customerEmployerAddress2],
          employerCity: [this.customerAccount.customerEmploymentInfoView.customerEmployerCity, Validators.required],
          employerState: [this.customerAccount.customerEmploymentInfoView.customerEmployerState, Validators.required],
          employerZip: [this.customerAccount.customerEmploymentInfoView.customerEmployerZipCode, [Validators.required, Validators.minLength(5)]],
          position: [this.customerAccount.customerEmploymentInfoView.customerEmploymentPosition, Validators.required]
        });
      }
    } else if (stepNumber === 4){
      if(this.customerAccount.customerEducationInfoView){
        this.educationForm = this.formBuilder.group({
          institutionName: [this.customerAccount.customerEducationInfoView.institutionName, Validators.required],
          institutionType: [this.customerAccount.customerEducationInfoView.institutionType, Validators.required],
          degree: [this.customerAccount.customerEducationInfoView.degree, Validators.required],
          graduated: [this.customerAccount.customerEducationInfoView.graduated],
          started: [this.customerAccount.customerEducationInfoView.started, Validators.required],
          ended: [this.customerAccount.customerEducationInfoView.ended]
        });
      }
    }
  }

  getCustomerProfile(uuid: string){
    this.customerService.getCustomerProfile(uuid).subscribe(
      profile => {
        this.customerProfile = profile;
        this.customerAccount.customerProfileInfoView = profile;
        this.profileInfoForm = this.formBuilder.group({
          dateOfBirth: [profile.dateOfBirth, Validators.required],
          gender: profile.gender,
          heightFeet:[this.getHeightFromInches(profile.height, 'feet'), Validators.required],
          heightInches:[this.getHeightFromInches(profile.height, 'inches'), Validators.required],
          weight: [profile.weight, Validators.required],
          race: profile.race,
          relationshipStatus: profile.relationshipStatus
        });
      }
    );
  }

  getCustomerContact(uuid: string){
    this.customerService.getCustomerContact(uuid).subscribe(
      contact => {
        this.customerContact = contact;
        this.customerAccount.customerContactInfoView = contact;
        this.contactInfoForm = this.formBuilder.group({
          addressLine1: [contact.address1, Validators.required],
          addressLine2: [contact.address2],
          countryCode: [contact.countryCode, Validators.required],
          phoneNumber: [contact.phoneNumber, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]], // Example phone number validation pattern
          city: [contact.city, Validators.required],
          state: [contact.state, Validators.required],
          zip: [contact.zipCode, [Validators.required, Validators.minLength(5)]]
        });
        if(!this.customerAccount.customerPlaidInfoView && this.customerAccount.customerSubscriptionView && this.customerAccount.customerSubscriptionView.subscribed){
          this.alertService.addAlert('info','Verification will start start');
          this.customerService.initialVerification(this.customerAccount.customerAccountUuid).subscribe({next: async url => {
              if (url) {
                this.alertService.addAlert('success', 'Initial Verification Successful!');
              } else {
                this.alertService.addAlert('error', 'Could Not Perform Initial Verification');
              }
            },
            error: err => {
              this.alertService.addAlert('error', 'Could Not Perform Initial Verification');
              console.error(err.message);
            }
          });
        }
      }
    )
  }

  getCustomerEmployment(uuid: string){
    this.customerService.getCustomerEmployment(uuid).subscribe(
      employment => {
        this.customerEmployment = employment;
        this.customerAccount.customerEmploymentInfoView = employment;
        this.employmentForm = this.formBuilder.group({
          employerName: [employment.customerEmployerName, Validators.required],
          companyPhoneNumber: [employment.customerEmployerPhoneNumber, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]], // Example phone number validation pattern
          employerAddress1: [employment.customerEmployerAddress1, Validators.required],
          employerAddress2: [employment.customerEmployerAddress2],
          employerCity: [employment.customerEmployerCity, Validators.required],
          employerState: [employment.customerEmployerState, Validators.required],
          employerZip: [employment.customerEmployerZipCode, [Validators.required, Validators.minLength(5)]],
          position: [employment.customerEmploymentPosition, Validators.required]
        });
      }
    );
  }

  onFileChange($event: any, uploadPictureModal:any) {
    this.imageChangedEvent = $event;
    this.modalService.open(uploadPictureModal, {ariaLabelledBy: 'staticBackdropLabel', centered: true, keyboard: false, backdrop: "static"});
  }

  imageCropped(event: ImageCroppedEvent): void {
    // Preview the cropped image
    this.croppedImage = event.blob;
  }

  imageLoaded(): void {
    // Image loaded on the cropper
  }

  cropperReady(): void {
    // Cropper ready
  }

  loadImageFailed(): void {
    // Image failed to load on the cropper
  }

  uploadCroppedImage(): void {
    // Use this.croppedImage to send the cropped image to the server
    this.loadingService.show();
    if(!this.croppedImage){
      this.alertService.addAlert('error', 'Cannot upload your picture');
      this.loadingService.hide();
    }

    this.customerService.uploadPhoto(this.croppedImage, this.customerAccount.customerAccountUuid)
      .subscribe(response =>{
        console.debug('response was ' + response);
        if(response.toString().includes('successful')){
          this.alertService.addAlert('success','Picture uploaded!');
          this.customerService.getCustomerProfile(this.customerAccount.customerAccountUuid)
            .subscribe(profile=>{
              profile.photoUrl = profile.photoUrl+"?"+(new Date()).getTime();
              this.customerAccount.customerProfileInfoView = profile;
              this.modalService.dismissAll();
              this.loadingService.hide();
            });
        }else {
          this.alertService.addAlert('error','Picture did upload!');
          this.loadingService.hide();
        }
      });
  }

  orderBackgroundCheck(plan: string) {
    this.loadingService.show();
    // Validate location
    if(!this.customerAccount.customerContactInfoView.state || !this.customerAccount.customerContactInfoView.city){
      this.alertService.addAlert('error','Contact Information needed');
      this.loadingService.hide();
    }else{
      this.customerService.orderBackgroundCheck(plan, this.customerAccount.customerAccountUuid).subscribe(async response =>{
        if(response){
          this.loadingService.hide();
          this.alertService.addAlert('success',"Payment link generated!");
          // Show payment link and QR Code
          this.isBackgroundLinkGenerated = true;
          this.backgroundLinkGenerated = response;
          this.qrCodeUrl = await this.qrCodeService.generateQRCode(this.backgroundLinkGenerated);
        }
      },error => {
        this.alertService.addAlert('error','Could not create background check payment link');
        this.loadingService.hide();
      });
    }
  }

  private convertToInches(feet: string, inches: string): number {
    return parseInt(feet) * 12 + parseInt(inches);
  }

  checkEmployment(content: any) {
    if(!this.customerAccount.customerEmploymentInfoView){
      // open modal step 3
      this.modalSelect(content, 3);
    }
  }

  checkEducation(content: any) {
    if(!this.customerAccount.customerEducationInfoView){
      this.modalService.open(content, {ariaLabelledBy: 'staticBackdropLabel', centered: true, keyboard: false, backdrop: "static"});
    }
  }
}
