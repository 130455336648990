import {Component, Input, OnInit} from '@angular/core';
import {CustomerSharingLink} from "../../models/CustomerSharingLink";
import {WsService} from "../../services/ws.service";
import {CustomerAccount} from "../../models/CustomerAccount";
import {FormBuilder} from "@angular/forms";
import {QrCodeService} from "../../services/qr-code.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoadingService} from "../../services/loading.service";
import {AlertService} from "../../services/alert.service";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-share-profile',
  templateUrl: './share-profile.component.html',
  styleUrls: ['./share-profile.component.css']
})
export class ShareProfileComponent implements OnInit{
  shareableLink?:CustomerSharingLink;
  @Input() customerData!: CustomerAccount
  shareProfileForm = this.formBuilder.group({
    shareName: true,
    shareEmail: false,
    sharePhoto: false,
    sharePhoneNumber: false,
    shareHeight: false,
    shareWeight: false,
    shareBirthDate : false,
    shareRace : false,
    shareGender : false,
    shareRelationshipStatus : false,
    shareAddress : false,
    shareEmployerName : false,
    shareEmploymentPosition : false,
    shareEmployerPhoneNumber : false,
    shareEmployerAddress : false,
    shareSSNTrace: false,
    shareSexOffenderRegistry: false,
    shareGlobalWatchlistRecords: false,
    shareNationalWatchlistRecords: false
  });
  qrCodeUrl: string | null = null;
  allSelected: boolean = false;
  isProd = environment.production;
  sharingLink: string = '';

  constructor(private formBuilder: FormBuilder, private wsService:WsService,
              private qrCodeService: QrCodeService, public modalService: NgbModal,
              private loadingService: LoadingService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.shareProfileForm.get('shareName')?.disable();
    if(localStorage.getItem('sb|sidebar-toggle') && localStorage.getItem('sb|sidebar-toggle')==='true'){
      document.body.classList.toggle('sb-sidenav-toggled');
    }
  }

  generateLink(content: any) {
    this.loadingService.show();
    this.wsService.addNewShareableLink(this.customerData?.customerAccountId!, this.shareProfileForm.value)
      .subscribe(async shareableLink => {
        this.shareableLink = shareableLink;
        this.loadingService.hide();
        this.alertService.addAlert('success','Shareable Link Generated!');
        this.modalService.open(content, {ariaLabelledBy: 'staticBackdropLabel', centered: true, keyboard: false, backdrop: "static"})
        this.qrCodeUrl = await this.qrCodeService.generateQRCode(this.shareableLink.linkUrl);
        if(!this.customerData.customerSharingLinkViews){
          this.customerData.customerSharingLinkViews = [];
        }
        this.customerData.customerSharingLinkViews.push(shareableLink);
      })
  }

  selectAllBoxes() {
    Object.keys(this.shareProfileForm.controls).forEach(key => {
      this.shareProfileForm.get(key)!.setValue(true);
    });
    this.allSelected = true;
  }

  deselectAllBoxes() {
    Object.keys(this.shareProfileForm.controls).forEach(key => {
      if(key !== 'shareName'){
        this.shareProfileForm.get(key)!.setValue(false);
      }
    });
    this.allSelected = false;
  }

  getLink(){
    if(!this.isProd){
      this.sharingLink = "https://app-dev.veriez.com"+this.shareableLink?.linkUrl;
    }else{
      this.sharingLink = "https://app.veriez.com"+this.shareableLink?.linkUrl;
    }
    return this.sharingLink;
  }
}
