import { Component } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {AlertService} from "../../services/alert.service";
import {WsService} from "../../services/ws.service";
import {LoadingService} from "../../services/loading.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent {

  constructor(private alertService: AlertService,public authService: AuthService,
              private customerService: WsService, private loadingService: LoadingService) {
  }

  sendVerificationMail(email: string){
    this.alertService.addAlert('info','Please wait while we set up your account!')
    this.customerService.sendEmailVerificationLink(email).subscribe({
      next: a=>{
        this.loadingService.hide();
        if(!a || a!=='SUCCESS'){
          this.alertService.addAlert('error', 'Cannot resend email verification link');
        }
      },
      error: err => {
        this.loadingService.hide();
        this.alertService.addAlert('error', 'Cannot resend email verification link');
      }
    })
  }
}
