import {Component, OnInit} from '@angular/core';
import {CustomerAccount} from "../../models/CustomerAccount";
import {ActivatedRoute} from "@angular/router";
import {WsService} from "../../services/ws.service";
import {LoadingService} from "../../services/loading.service";
import {FormBuilder} from "@angular/forms";
import html2canvas from "html2canvas";
import {QrCodeService} from "../../services/qr-code.service";
import {CustomerVerificationDetail} from "../../models/CustomerVerificationDetail";

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.css']
})
export class PublicProfileComponent implements OnInit{
  customerAccount?: CustomerAccount;
  link_url: string = '';
  qrCodeUrl: string | null = null;
  public age: number = 0;
  verificationDetail: CustomerVerificationDetail = <CustomerVerificationDetail>{};

  constructor(private route: ActivatedRoute, private customerService:WsService,
              private loadingService: LoadingService, private qrCodeService: QrCodeService) {
    this.loadingService.show();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.link_url = params['link_url'];
      this.customerService.getPublicProfile(this.link_url).subscribe(async cAccount=>{
        if(cAccount){
          this.customerAccount = cAccount;
          if(this.customerAccount.customerVerificationInfoViews){
            this.customerAccount.customerVerificationInfoViews.forEach((view)=>{
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'PHOTO' && view.verified){
                this.verificationDetail.photoVerified = true;
                this.verificationDetail.photoVerifiedBy = view.verifiedBy;
                this.verificationDetail.photoVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'BIRTHDATE' && view.verified){
                this.verificationDetail.birthDateVerified = true;
                this.verificationDetail.birthDateVerifiedBy = view.verifiedBy;
                this.verificationDetail.birthDateVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'HEIGHT' && view.verified){
                this.verificationDetail.heightVerified = true;
                this.verificationDetail.heightVerifiedBy = view.verifiedBy;
                this.verificationDetail.heightVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'WEIGHT' && view.verified){
                this.verificationDetail.weightVerified = true;
                this.verificationDetail.weightVerifiedBy = view.verifiedBy;
                this.verificationDetail.weightVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'GENDER' && view.verified){
                this.verificationDetail.genderVerified = true;
                this.verificationDetail.genderVerifiedBy = view.verifiedBy;
                this.verificationDetail.genderVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'RACE' && view.verified){
                this.verificationDetail.raceVerified = true;
                this.verificationDetail.raceVerifiedBy = view.verifiedBy;
                this.verificationDetail.raceVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'PROFILE' && view.customerCategoryField === 'RELATIONSHIP' && view.verified){
                this.verificationDetail.relationshipStatusVerified = true;
                this.verificationDetail.relationshipStatusVerifiedBy = view.verifiedBy;
                this.verificationDetail.relationshipStatusVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'NAME' && view.verified){
                this.verificationDetail.nameVerified = true;
                this.verificationDetail.nameVerifiedBy = view.verifiedBy;
                this.verificationDetail.nameVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'ADDRESS' && view.verified){
                this.verificationDetail.addressVerified = true;
                this.verificationDetail.addressVerifiedBy = view.verifiedBy;
                this.verificationDetail.addressVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'PHONE' && view.verified){
                this.verificationDetail.phoneNumberVerified = true;
                this.verificationDetail.phoneNumberVerifiedBy = view.verifiedBy;
                this.verificationDetail.phoneNumberVerifiedTimestamp = view.verificationTimestamp;
              }
              if(view.customerCategory === 'CONTACT' && view.customerCategoryField === 'EMAIL' && view.verified){
                this.verificationDetail.emailVerified = true;
                this.verificationDetail.emailVerifiedBy = view.verifiedBy;
                this.verificationDetail.emailVerifiedTimestamp = view.verificationTimestamp;
              }
            })
          }
          this.age = this.calculateAge(this.customerAccount.customerProfileInfoView.dateOfBirth);
          this.qrCodeUrl = await this.qrCodeService.generateQRCode("https://app.veriez.com/public/"+this.link_url);
          this.loadingService.hide();
        }
      })
    });
  }

  downloadScreenshot() {
    const publicProfileDivId = document.getElementById('publicProfileDivId');
    html2canvas(publicProfileDivId!, {
      useCORS: true
    }).then(canvas => {
      // Convert canvas to an image
      const image = canvas.toDataURL('image/png');

      // Create a link to download
      const link = document.createElement('a');
      link.href = image;
      link.download = 'profile-screenshot.png';
      link.click();
    });
  }

  calculateAge(birthdate: Date): number {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }
}
