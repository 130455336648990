<div class="wrap-without-sidebar bg-light min-vh-100 top-0">
  <main>
    <div class="container">
      <div class="authentication">
        <div class="card-body">
          <div *ngIf="isEmailVerifyMode">
            <div class="text-center mb-4">
              <a routerLink="/"><img class="mb-5" src="assets/img/veriez-logo.png" alt="VeriEZ" width="138" height="31"></a>
              <h6 class="mb-0">Thank You for Registering</h6>
            </div>
            <div class="form-column mb-3" *ngIf="isEmailVerified">
              <p class="text-center">Your email was successfully verified!</p>
              <p class="text-center">Please proceed to <strong><a routerLink="/sign-in">Sign In</a></strong></p>
            </div>
            <div class="form-column mb-3" *ngIf="!isEmailVerified">
              <p class="text-center">Your email could not be verified!</p>
              <p class="text-center">A member of our team will reach out</p>
            </div>
          </div>
          <div *ngIf="isResetPasswordMode">
            <div class="text-center mb-4">
              <a routerLink="/"><img class="mb-5" src="assets/img/veriez-logo.png" alt="VeriEZ" width="138" height="31"></a>
              <h6 class="mb-0">Reset your password</h6>
            </div>
            <form [formGroup]="newPasswordForm" (ngSubmit)="handleResetPassword()" *ngIf="!isResetPassword">
              <div class="form-column mb-3">
                <label for="password" class="col-form-label">Password</label>
                <input type="password" class="form-control" id="password" name="password" formControlName="password" placeholder="Password">
                <div *ngIf="newPasswordForm.get('password')?.errors && newPasswordForm.get('password')?.touched">
                  <small class="text-danger" *ngIf="newPasswordForm.get('password')?.errors?.['required']">
                    Password is required.
                  </small>
                  <small class="text-danger" *ngIf="newPasswordForm.get('password')?.errors?.['minLength']">
                    Enter a valid Password.
                  </small>
                  <small class="text-danger" *ngIf="newPasswordForm.get('password')?.errors?.['passwordStrength']">
                    Password must be at least 8 characters, contain one uppercase letter, and one symbol.
                  </small>
                </div>
              </div>
              <div class="form-column mb-3">
                <label for="repeatPassword" class="col-form-label">Repeat Password</label>
                <input type="password" class="form-control" id="repeatPassword" name="repeatPassword" formControlName="repeatPassword" placeholder="Password Confirmation">
                <small class="text-danger" *ngIf="newPasswordForm.get('password')?.value !== newPasswordForm.get('repeatPassword')?.value">
                  Passwords do not match.
                </small>
              </div>
              <button type="submit" class="btn btn-primary w-100 mb-4" [disabled]="!newPasswordForm.valid || newPasswordForm.get('password')?.value !== newPasswordForm.get('repeatPassword')?.value">Sign Up</button>
            </form>
            <div class="form-column mb-3" *ngIf="isResetPassword">
              <p class="text-center">Your password was successfully reset!</p>
              <p class="text-center">Please proceed to <strong><a routerLink="/sign-in">Sign In</a></strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>


