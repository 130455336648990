import {Component, Input, OnInit} from '@angular/core';
import {CustomerSharingLink} from "../../models/CustomerSharingLink";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit{
  @Input() sharedLinks!: CustomerSharingLink[];
  isProd = environment.production;

  ngOnInit(): void {
    if(localStorage.getItem('sb|sidebar-toggle') && localStorage.getItem('sb|sidebar-toggle')==='true'){
      document.body.classList.toggle('sb-sidenav-toggled');
    }
  }

}
