import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {SignInComponent} from "./components/sign-in/sign-in.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {SignUpComponent} from "./components/sign-up/sign-up.component";
import {VerifyEmailComponent} from "./components/verify-email/verify-email.component";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";
import { AuthGuard } from './shared/guard/auth.guard';
import {SharedProfileComponent} from "./components/shared-profile/shared-profile.component";
import {PaymentConfirmationComponent} from "./components/payment-confirmation/payment-confirmation.component";
import {PublicProfileComponent} from "./components/public-profile/public-profile.component";
import {EmailVerificationComponent} from "./components/email-verification/email-verification.component";

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },
  { path: 'email-verification', component: EmailVerificationComponent },
  { path: 'share/:link_url', component: SharedProfileComponent },
  { path: 'public/:link_url', component: PublicProfileComponent },
  { path: 'confirmation', component: PaymentConfirmationComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
