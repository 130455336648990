import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { AppRoutingModule } from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import {HttpClientModule} from "@angular/common/http";
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ShareProfileComponent } from './components/share-profile/share-profile.component';
import {NgxStripeModule} from "ngx-stripe";
import { UploadComponent } from './components/upload/upload.component';
import { SharedProfileComponent } from './components/shared-profile/shared-profile.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import { PaymentConfirmationComponent } from './components/payment-confirmation/payment-confirmation.component';
import {NgOptimizedImage} from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import {ClipboardModule} from "ngx-clipboard";
import { PublicProfileComponent } from './components/public-profile/public-profile.component';
import { AlertComponent } from './components/alert/alert.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { BooleanToYesNoPipe } from './pipes/boolean-to-yes-no.pipe';
import { ConvertHeightPipe } from './pipes/convert-height.pipe';
import { PhoneNumberFormatDirective } from './directives/phone-number-format.directive';
import {ImageCropperModule} from "ngx-image-cropper";
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import {getAnalytics, provideAnalytics} from "@angular/fire/analytics";
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    SignUpComponent,
    CustomerDetailComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    SettingsComponent,
    ShareProfileComponent,
    UploadComponent,
    SharedProfileComponent,
    PaymentConfirmationComponent,
    AnalyticsComponent,
    PublicProfileComponent,
    AlertComponent,
    BooleanToYesNoPipe,
    ConvertHeightPipe,
    PhoneNumberFormatDirective,
    EmailVerificationComponent,
    NotificationBannerComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(()=> getAnalytics()),
    provideAuth(() => getAuth()),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot('pk_test_51Nqqi5B2slI8vy1S1zwMBurvwZ9cXMZcBiRMHouzNWtUPObvkuMUcbLbD3t0GhTOYGk42ybPRDONUubRsgABub7700LG2ODngM'),
    RecaptchaV3Module,
    NgOptimizedImage,
    NgbModule,
    ClipboardModule,
    BrowserAnimationsModule,
    ImageCropperModule
  ],
  providers: [{
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: '6LeMqMEoAAAAAJUFkTr_hA2ESHgLLu_JQove3s20'
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
