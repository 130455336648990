import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertHeight'
})
export class ConvertHeightPipe implements PipeTransform {

  transform(value: string): string {
    if(value && value!=='') {
      const feet = Math.floor(parseInt(value) / 12);
      const inches = parseInt(value) % 12;
      return `${feet}' ${inches}"`;
    }
    return '';
  }

}
