<div class="content-wrapper-top">
  <h3 class="mb-0">Settings</h3>
</div>
<div class="content-wrapper-middle">
  <h5 class="mb-4">Change Password</h5>
  <form [formGroup]="form1" class="change-pwd-form">
    <div class="mb-3">
      <label for="oldPwd" class="form-label">Old Password</label>
      <input formControlName="oldPwd" id="oldPwd" type="password" class="form-control" placeholder="Enter Old Password"/>
      <div *ngIf="form1.get('password')?.errors && form1.get('password')?.touched">
        <small class="text-danger" *ngIf="form1.get('password')?.errors?.['required']">
          Password is required.
        </small>
        <small class="text-danger" *ngIf="form1.get('password')?.errors?.['minLength']">
          Enter a valid Password.
        </small>
        <small class="text-danger" *ngIf="form1.get('password')?.errors?.['passwordStrength']">
          Password must be at least 8 characters, contain one uppercase letter, and one symbol.
        </small>
      </div>
    </div>
    <div class="mb-3">
      <label for="newPwd" class="form-label">New Password</label>
      <input formControlName="newPwd" id="newPwd" type="password" class="form-control" placeholder="Enter New Password"/>
      <div *ngIf="form1.get('password')?.errors && form1.get('password')?.touched">
        <small class="text-danger" *ngIf="form1.get('password')?.errors?.['required']">
          Password is required.
        </small>
        <small class="text-danger" *ngIf="form1.get('password')?.errors?.['minLength']">
          Enter a valid Password.
        </small>
        <small class="text-danger" *ngIf="form1.get('password')?.errors?.['passwordStrength']">
          Password must be at least 8 characters, contain one uppercase letter, and one symbol.
        </small>
      </div>
    </div>
    <div class="mb-3">
      <label for="confirmPwd" class="form-label">Confirm New Password</label>
      <input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control" placeholder="Confirm New Password"/>
      <div *ngIf="form1.get('password')?.errors && form1.get('password')?.touched">
        <small class="text-danger" *ngIf="form1.get('password')?.errors?.['required']">
          Password is required.
        </small>
        <small class="text-danger" *ngIf="form1.get('password')?.errors?.['minLength']">
          Enter a valid Password.
        </small>
        <small class="text-danger" *ngIf="form1.get('password')?.errors?.['passwordStrength']">
          Password must be at least 8 characters, contain one uppercase letter, and one symbol.
        </small>
      </div>
    </div>
    <button type="submit" class="btn btn-primary" (submit)="submitChangePassword()" [disabled]="!form1.valid">Submit</button>
  </form>
  <hr class="my-5" />
  <h5>Subscription</h5>
  <div class="d-flex flex-wrap justify-content-between align-items-center bg-primary rounded rounded-4 py-3 px-4">
    <h2 class="display-5 fw-bolder text-white mb-3 mb-sm-0">$8.99<sub class="fs-5 fw-normal">/ Month</sub></h2>
    <a href="https://billing.stripe.com/p/login/9AQ9Ee2bM4vQcaQfYY" type="button" class="btn btn-light color-primary">Manage Subscription</a>
  </div>
</div>
