<!-- Top navigation -->
<nav class="sb-topnav navbar navbar-expand">
  <!-- Logo Image -->
  <a class="navbar-brand" routerLink="/"><img ngSrc="assets/img/veriez-logo.png" alt="Logo" width="98" height="22"></a>
  <!-- Sidebar Toggle-->
  <button class="order-1 order-lg-0 ms-auto d-lg-none" id="sidebarToggle" href="javascript:void(0)" (click)="toggleLeftNav()">
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
      <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" fill="#0074DE"></path>
    </svg>
  </button>
  <!-- Logout Button -->
  <ul class="navbar-nav ms-auto d-none d-lg-block">
    <li class="nav-item">
      <a class="btn btn-outline-danger" (click)="authService.signOut()"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M2 18C1.45 18 0.979002 17.804 0.587002 17.412C0.195002 17.02 -0.000664969 16.5493 1.69779e-06 16V2C1.69779e-06 1.45 0.196002 0.979002 0.588002 0.587002C0.980002 0.195002 1.45067 -0.000664969 2 1.69779e-06H9V2H2V16H9V18H2ZM13 14L11.625 12.55L14.175 10H6V8H14.175L11.625 5.45L13 4L18 9L13 14Z" fill="#C62929"></path></svg>Logout</a>
    </li>
  </ul>
</nav>

<!-- Sidebar navigation -->
<div id="layoutSidenav">
  <!-- Left Sidebar Nav -->
  <div id="layoutSidenav_nav" class="layout-sidenav">
    <nav class="sb-sidenav" id="">
      <div class="sb-sidenav-menu">
        <div class="nav">
          <a class="nav-link active" id="menu_profile" (click) = "selectedMenu('profile')">
            <span class="sb-nav-link-icon"><img src="assets/img/user-profile-icon.svg" alt="User Profile"></span>
            User Profile
            <span class="sb-sidenav-collapse-arrow"><img src="assets/img/right-caret-icon.svg" alt="Icon"></span>
          </a>
          <a class="nav-link" id="menu_share" (click) = "selectedMenu('share')">
            <span class="sb-nav-link-icon"><img src="assets/img/share-profile-icon.svg" alt="Share Profile"></span>
            Share Profile
            <span class="sb-sidenav-collapse-arrow"><img src="assets/img/right-caret-icon.svg" alt="Icon"></span>
          </a>
          <a class="nav-link" id="menu_analytics" (click) = "selectedMenu('analytics')">
            <span class="sb-nav-link-icon"><img src="assets/img/analytics-icon.svg" alt="Analytics"></span>
            Analytics
            <span class="sb-sidenav-collapse-arrow"><img src="assets/img/right-caret-icon.svg" alt="Icon"></span>
          </a>
          <a class="nav-link" id="menu_verification" (click) = "selectedMenu('verification')">
            <span class="sb-nav-link-icon"><img src="assets/img/verification-icon.svg" alt="Verification"></span>
            Verification
            <span class="sb-sidenav-collapse-arrow"><img src="assets/img/right-caret-icon.svg" alt="Icon"></span>
          </a>
          <a class="nav-link" id="menu_settings" (click) = "selectedMenu('settings')">
            <span class="sb-nav-link-icon"><img src="assets/img/settings-icon.svg" alt="Settings"></span>
            Settings
            <span class="sb-sidenav-collapse-arrow"><img src="assets/img/right-caret-icon.svg" alt="Icon"></span>
          </a>
        </div>
      </div>
      <div class="sb-sidenav-footer d-lg-none">
        <a class="btn btn-outline-danger" (click)="authService.signOut()"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M2 18C1.45 18 0.979002 17.804 0.587002 17.412C0.195002 17.02 -0.000664969 16.5493 1.69779e-06 16V2C1.69779e-06 1.45 0.196002 0.979002 0.588002 0.587002C0.980002 0.195002 1.45067 -0.000664969 2 1.69779e-06H9V2H2V16H9V18H2ZM13 14L11.625 12.55L14.175 10H6V8H14.175L11.625 5.45L13 4L18 9L13 14Z" fill="#C62929"></path></svg>Logout</a>
      </div>
    </nav>
  </div>
  <app-notification-banner [showBanner]="showBanner" [bannerMessage]="bannerMessage" [url]="bannerUrl"></app-notification-banner>
  <div id="layoutSidenav_content" class="main-content-layout">
    <!-- Main Content Area -->
    <main *ngIf="customerAccount">
      <div *ngIf="isProfileSelected">
        <app-customer-detail [customerAccount] = "customerAccount" [verificationDetail] = "verificationDetail"></app-customer-detail>
      </div>
      <div *ngIf="isSettingSelected">
        <app-settings></app-settings>
      </div>
      <div *ngIf="isShareSelected">
        <app-share-profile [customerData] = "customerAccount"></app-share-profile>
      </div>
      <div *ngIf="isVerificationSelected">
        <app-upload [tasks]="tasks" [customerAccount] = "customerAccount"></app-upload>
      </div>
      <div *ngIf="isAnalyticsSelected">
        <app-analytics [sharedLinks] = "customerAccount.customerSharingLinkViews"></app-analytics>
      </div>
    </main>
    <app-footer></app-footer>
  </div>
</div>

<ng-template #modalContent let-modal>
  <div class="modal-header px-4">
    <h4 class="modal-title">Let's Activate Your Account!</h4>
  </div>
  <div class="modal-body form-body px-4 text-center">
    <div class="form-column">
      <p>Unlock all features by subscribing below</p>
      <button id="payment_link_button_modal_id" type="button" class="btn btn-primary" (click)="goToSubscription()">Subscribe To Activate</button>
    </div>
  </div>
  <div class="modal-footer text-center">
    <button class="btn btn-outline-danger" (click)="modalService.dismissAll()">Activate and Verify Later</button>
  </div>
</ng-template>

<ng-template #newUserModalContent let-modal>
  <div class="stepper-wrapper mb-4">
    <div class="progress" style="height: 10px;">
      <div class="progress-bar" role="progressbar" style="width: 33%;" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100" *ngFor="let step of steps; let i = index" [class.bg-primary]="step.active" [class.bg-secondary]="!step.active" [attr.aria-valuenow]="(i + 1) * (100 / steps.length)" [style.width]="(100 / steps.length) + '%'" ></div>
    </div>
  </div>
  <div class="modal-header px-4">
    <h5 class="modal-title">Welcome! First things first...</h5>
  </div>
  <div class="modal-body form-body px-4">
    <ng-container *ngIf="currentStep === 0">
      <form [formGroup]="marketingForm">
        <div class="form-column text-center mb-4">
          <img src="assets/img/VeriEZ.svg" width="55%" alt="Main VeriEZ Logo">
        </div>
        <div class="form-column">
          <label for="referer" class="form-label">How did you hear about us?</label>
          <select class="form-control" id="referer" formControlName="referer">
            <option value="" *ngIf="!marketingForm.get('referer')?.value">Select an option</option>
            <option value="facebook">Facebook</option>
            <option value="google">Google</option>
            <option value="linkedin">LinkedIn</option>
            <option value="instagram">Instagram</option>
            <option value="other">Other</option>
          </select>
        </div>
        <input *ngIf="showOtherInput" type="text" class="form-control" formControlName="otherInput" placeholder="Please specify">
      </form>
    </ng-container>
    <ng-container *ngIf="currentStep === 1">
      <form [formGroup]="profileInfoForm">
        <!--<div class="form-column">
          <label for="profilePicture" class="col-form-label">Upload Picture</label>
          <input type="file" class="form-control" id="profilePicture" name="profilePicture" formControlName="profilePicture" (change)="onFileChange($event)">
        </div>-->
        <div class="form-column">
          <label for="dateOfBirth" class="col-form-label">Date of Birth</label>
          <input type="date" class="form-control" id="dateOfBirth" name="dateOfBirth" formControlName="dateOfBirth">
        </div>
        <div class="form-column">
          <label class="col-form-label">Height</label>
          <div class="input-group">
            <input type="text" class="form-control" id="heightFeet" name="heightFeet" formControlName="heightFeet" placeholder="Feet">
            <input type="text" class="form-control" id="heightInches" name="heightInches" formControlName="heightInches" placeholder="Inches">
          </div>
        </div>
        <div class="form-column">
          <label for="weight" class="col-form-label">Weight (Lbs)</label>
          <input type="text" class="form-control" id="weight" name="weight" formControlName="weight">
        </div>
        <div class="form-column">
          <label for="gender" class="col-form-label">Gender</label>
          <select id="gender" name="gender" class="form-control" formControlName="gender" #genderValue>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="OtherGender">Other</option>
          </select>
        </div>
        <div class="form-column" *ngIf="genderValue && genderValue.value && genderValue.value === 'OtherGender'">
          <input type="text" class="form-control" formControlName="gender">
        </div>
        <div class="row ">
          <div class="col-sm-6">
            <div class="form-column">
              <label for="race" class="col-form-label">Race</label>
              <select id="race" name="race" class="form-control" formControlName="race">
                <option value="Caucasian">White or Caucasian</option>
                <option value="HispanicLatino">Hispanic or Latino</option>
                <option value="AfricanAmerican">Black or African American</option>
                <option value="AmericanIndian">American Indian</option>
                <option value="NativeHawaiian">Native Hawaiian</option>
                <option value="AlaskanNative">Alaskan Native</option>
                <option value="Asian">Asian</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-column">
              <label for="relationshipStatus" class="col-form-label">Relationship Status:</label>
              <select id="relationshipStatus" name="relationshipStatus" class="form-control" formControlName="relationshipStatus">
                <option value="Single">Single</option>
                <option value="InRelationship">In a relationship</option>
                <option value="Engaged">Engaged</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
                <option value="Widowed">Widowed</option>
                <option value="Separated">Separated</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="currentStep === 2">
      <form [formGroup]="contactInfoForm">
        <div class="form-column">
          <label for="addressLine1" class="form-label">Address Line 1</label>
          <input type="text" class="form-control" id="addressLine1" formControlName="addressLine1" placeholder="1234 Main St">
        </div>
        <div class="form-column">
          <label for="addressLine2" class="form-label">Address Line 2</label>
          <input type="text" class="form-control" id="addressLine2" formControlName="addressLine2" placeholder="Apartment or suite">
        </div>
        <div class="form-column">
          <label for="city" class="form-label">City</label>
          <input type="text" class="form-control" id="city" formControlName="city" placeholder="City">
        </div>
        <div class="form-column">
          <label for="state" class="form-label">State</label>
          <select class="form-control" id="state" formControlName="state">
            <option *ngFor="let state of states" [value]="state.abbreviation">
              {{state.name}}
            </option>
          </select>
        </div>
        <div class="form-column">
          <label for="zip" class="form-label">Zip</label>
          <input type="text" class="form-control" id="zip" formControlName="zip" placeholder="12345">
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="currentStep === 3">
      <h6><button type="button" class="btn btn-secondary" (click)="skipEmployment()">Skip</button></h6>
      <form [formGroup]="employmentForm">
        <div class="modal-body form-body px-4">
          <div class="form-column">
            <label for="employerName" class="form-label">Employer Name</label>
            <input type="text" class="form-control" id="employerName" formControlName="employerName" placeholder="Company Inc.">
          </div>
          <div class="form-column">
            <label for="position" class="form-label">Employment Position</label>
            <input type="text" class="form-control" id="position" formControlName="position" placeholder="Software Developer">
          </div>
          <div class="form-column">
            <label for="companyPhoneNumber" class="form-label">Employer Phone Number</label>
            <input type="tel" appPhoneNumberFormat class="form-control" id="companyPhoneNumber" formControlName="companyPhoneNumber" placeholder="123-456-7890">
          </div>
          <div class="form-column">
            <label for="employerAddress1" class="form-label">Employer Address Line 1</label>
            <input type="text" class="form-control" id="employerAddress1" formControlName="employerAddress1" placeholder="1234 Main St">
          </div>
          <div class="form-column">
            <label for="employerAddress2" class="form-label">Employer Address Line 2</label>
            <input type="text" class="form-control" id="employerAddress2" formControlName="employerAddress2" placeholder="Apartment or suite">
          </div>
          <div class="form-column">
            <label for="employerCity" class="form-label">Employer City</label>
            <input type="text" class="form-control" id="employerCity" formControlName="employerCity" placeholder="City">
          </div>
          <div class="form-column">
            <div class="col-md-6 mb-3">
              <label for="employerState" class="form-label">Employer State</label>
              <select class="form-select" id="employerState" formControlName="employerState">
                <option *ngFor="let state of states" [value]="state.abbreviation">
                  {{state.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-column">
            <div class="col-md-6 mb-3">
              <label for="employerZip" class="form-label">Employer Zip</label>
              <input type="text" class="form-control" id="employerZip" formControlName="employerZip" placeholder="12345">
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="currentStep === 4">
      <div class="text-center">
        <button id="payment_link_button_id" type="button" class="btn btn-primary" (click)="goToSubscription()">Subscribe To Start Verifying</button>
      </div>
    </ng-container>
    <ng-container *ngIf="currentStep === 5">
      <div class="form-column">
        <div *ngIf="qrCodeUrl">
          <p>Scan this code with your phone</p>
          <img [src]="qrCodeUrl" alt="QR Code" />
          <button class="btn btn-link" (click)="redirectToPlaid()">Or click here</button>
        </div>
        <div *ngIf="!qrCodeUrl">
          <p>An email will be sent to your email with a link to verify your info!</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer justify-content-between border-0 px-4 pb-4 pt-0">
    <button type="button" [disabled]="currentStep==0" class="btn btn-secondary" (click)="prevStep()">Back</button>
    <button type="button" class="btn btn-secondary"  *ngIf="currentStep === 3" (click)="skipEmployment()">Skip</button>
    <button type="button" class="btn btn-outline-primary" (click)="nextStep()" [disabled]="!profileInfoForm.valid
        && !contactInfoForm.valid && !employmentForm.valid && !identificationForm.valid && !marketingForm.dirty" *ngIf="currentStep !== 4">
      {{ currentStep === 5 ? 'Finish' : 'Next' }}
    </button>
    <button class="btn btn-outline-primary" *ngIf="currentStep === 4" (click)="modalService.dismissAll()">Activate and Verify Later</button>
  </div>
</ng-template>
